//@flow
import React from 'react';
import ReactDOM from "react-dom";
import { View, ViewTitle } from '../../../shared/styled/view';
import { connect } from 'react-redux';
import { readEntities, getEntityItems, readEntity, partialEntity, createEntity, deleteEntity } from '../../../redux/api';
import { getUrlsEnv } from '../../../redux/api/api-urls';
import { getLastSession, sendMessageToMultipleUsersInSocket } from '../../../shared/utils';
import Tag from '../../../shared/controls/tag';
import SelectInput from '../../../shared/controls/select-input';
import TextInput from '../../../shared/controls/text-input';
import Button from '../../../shared/controls/button';
import TabSelectorRibbon from '../../../shared/controls/tab-selector-ribbon';
import { MapController } from '../../../shared/component/map-container';
import Unitary from '../../../shared/icons/assemble/unitary';
import LocationPin from '../../../shared/icons/assemble/location-pin';    
import { TimeLine } from '../../../shared/animation';
import ValidatePerms from "../../../shared/component/validate-perms";
import { loadSession, removeOrderCommentsSeleted, removeOrderSeleted, removeToast, setOrderSeleted, validateObjPerms } from '../../../redux/reducers/global-catalog.reducer';
import { loadTags } from "../../../redux/reducers/tags.reducer";
import Chip from '../../../shared/controls/chip';
import SVGIcon, { icons } from '../../../shared/svg-icon';
import { openSecureDoc } from '../../../shared/backend-api';
import { Dolly, DoubleEquipment, Equipment, Operator, Truck, TruckDoubleEquipment, TruckEquipment } from '../../../shared/icons/assemble';
import ReactTooltip from 'react-tooltip';
import { AssembleAvatar } from '../../assemble/assemble-avatar';
import GroupContainer from '../../../company/company/group-container';
import { StyledTag } from '../../../company/company/styled-tag';
import BaseDialog from '../../../shared/dialog/base-dialog';
import Checkbox from '../../../shared/controls/checkbox';
import Loader from '../../../shared/icons/tat/loader';
import CommentInput from './CommentInput';
import { ActionsTricolon } from '../../../shared/controls/actions-tricolon';
import ChipContainer from "../../../shared/component/chip-container";
import Axios from "axios";
import { SERVER } from "../../../redux/api/api-urls";
import * as moment from "moment-timezone";
import "moment/locale/es";
import XMLIcon from '../../../shared/icons/xml-icon';
import PDFIcon from '../../../shared/icons/pdf-icon';
import { setNavbarAction, removeNavbarAction } from '../../../redux/reducers/navbar-reducer';
import HelperFilesModal from '../../../shared/component/helper_files_modal';
import DownloadImage from '../../../shared/icons/routes/downloadIcon';
import styled from "styled-components";
import ImageDialog from '../../../shared/dialog/image-modal';
import ErrorDialog from '../../../shared/dialog/error-dialog';
import ChangeDateModal from '../../../shared/dialog/change-date-modal';
import { cloneDeep } from 'lodash';
import { ToastContainer, toast } from "react-toastify";
import SocketIO from '../../../shared/socket-io';
import ImageWithCheckMark from '../../../shared/component/image-with-check-mark';


type StateToProps = {|
  companyId: number,
|};

type DispatchToProps = {|
  
|};

type OwnProps = {|
  history: RouterHistory,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};
type State = {
};

const { files } = getUrlsEnv();

const color = "#7ED321";

const GEOFENCE_TYPES = {
  1: {
    name: "PROXIMITY",
    description: "Proximidad",
    id: 1,
    color: "#55B740",
  },
  2: {
    name: "GATE",
    description: "Entradas",
    id: 2,
    color: "#5EB5FF",
  },
  3: {
    name: "VALIDATION",
    description: "Validaciones",
    id: 3,
    color: "#FFF845",
  },
};

const TABS = [
  {
    id: "3",
    description: "Comentarios"
  },
  {
    id: "1",
    description: "Historial de Orden"
  },
  {
    id: "2",
    description: "Mapa"
  }
]

const MONTHS = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
]

class ShipmentOrderDetailView extends React.Component<Props, State> {


  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      selectedTab: "1",
      isLoading: false,

      locationContainers: [],
      selectedLocationContainer: 0,
      historyContainers: [],
      selectedHistoryContainer: 0,

      companyTags: [],
      selectedCompanyTag: [],
      selectedorderTags: [],
      selectedTripTags: [],
      selectedClass: 0,
      seelectedTrip: '',
      selectedOrderCode: '',
      currentOrder: {},

      routeLocations: [],
      routePoints: [],

      isPlaying: false,
      routeDistance: 0,
      speed: 80000,

      selectedComment: 0,
      currentReply: "",
      replyFiles: [],
      showFavReplies: false,
      newFavReply: "",
      favReply: -1,

      currentComment: "",
      commentFiles: [],
      showFavComments: false,
      newFav: "",
      favMsg: -1,

      showDeleteEventModal: false,
      selectedEvent: null,
      deleteForAllContainers: true,

      showEditOrderModal: false,
      optionEditOrderModal: "",
      titleEditOrderMdal: "",
      editOrderTrip: "",

      errorEditOrderTrip: false,
      msgEditOrderTrip: "",

      candidatesTripToChange: [],

      originTimezone: '',
      destinationTimezone: '',
      downloadingFile: false,
      errorMessageModal: null,
      shownewAppointmentModal: false,
      showEditAppointmentModal: false,
      manueverToAssign: '',
      appointmentChangeData: null,
      requests: {
        load: {pending: 0, accepted: 0, cancelled: 0},
        unload: {pending: 0, accepted: 0, cancelled: 0}
      },
      commentsPending: [],
      trip_id: null,
      downloadingFileComments: {},
      isTripAllowed: true
    };

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };

    this.routeLine = null;
    this.mapController = new MapController();
    this.routeTimeline = null;
    this.geoFences = [];
    this.routeGeofences = [];
  }

  componentDidUpdate(prevProps, prevState) {
    // funcion para actualizar el viaje cuando viene de una notificacion y el usuario esta en la misma pantalla de detalle de orden
    if (this.state.trip_id !== null && `${this.state.trip_id}` !== `${this.props.match.params.order}`) {
      this.setState({
        trip_id: null
      }, () => {
        this.validateTrip();
      });
    }
  }

  componentDidMount() {
    this.validateTrip();
  }

  validateTrip = () => {
    this.props.validateTrip(this.props.companyId, this.props.match.params.order, {
      onSuccess: (t) => {
        if ('belonging' in t && t.belonging === true) {
          this.loadTripWebservices();
        } else {
          this.setState({isTripAllowed: false});
        }
      },
      onError: () => {
        this.loadTripWebservices();
      }
    });
  }

  loadTripWebservices = () => {

    document.addEventListener('mousedown', this.handlePageContentScroll, false);
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    document.addEventListener('order-comment', this.handleSocketMessage, false);
    document.addEventListener('user-update', this.handleSocketPersonalMessage, false);

    this.props.loadTimeZonesCatalog();
    this.props.loadCompanyTags(this.props.companyId);
    this.props.loadCompanyTransportClass(this.props.companyId);
    this.props.loadFavoriteComments({
      company_id: this.props.companyId,
      company_user: this.props.companyUser.id,
    })
    // this.loadOrder();
    // this.routeTimeline.addEventListener(
    //   Event.CHANGE,
    //   "updateRouteAnimation",
    //   this
    // );
    // Object.observe(this.routeTimeline, this.updateRouteAnimation );
    this.routeTimeline = new TimeLine(20000, this.updateRouteAnimation);
    this.loadTrip();
    this.loadTags();
    let self = this;
    this.mapController.loadMap(null, null, null, "roadmap", false);
    this.mapController.isReadySubject.subscribe((ready) => {
      if (ready) {
        let mapDiv = document.getElementById('map-container').getElementsByTagName('div')[0];
        mapDiv.appendChild(document.getElementById("play-button"));
        // this.loadRoute();
        window.google.maps.event.addListener(
          this.mapController.map,
          "click",
          function (event) {
            self.mapController.hideInfoWindow();
          }
        );
      }
    });

    this.props.setNavbarAction("order-details", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  buildTimeZoneFormat = (event) => {
    return(
      <>
        {
          (event.created 
            && moment(event.created)
              .tz(event.time_zone ? event.time_zone : (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone))
              .format('DD/MMM HH:mm') 
          ) || '-'
        }
        {` Zona horaria: ${event.time_zone ? event.time_zone : (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone)}`}
      </>
    );
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && e.target.id.includes('container-eta')) {
      return;
    }
    ReactTooltip.hide();
  }

  setNavBar = () => {

    this.props.setNavbarAction("order-details", [
      {
        icon: this.state.downloadingFile ? <Loader width="20px" height="20px"/> : 
        <img
          alt=""
          src={"/images/download_icon.png"}
          style={{width: 20, height: 20, filter: 'invert(100%)'}}
        />,
        onClick: (e) => {
          if (!this.state.downloadingFile) {
            e.stopPropagation(); 
            this.openOrderDetailDocument()
          }
        }
      },
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    document.removeEventListener('order-comment', this.handleSocketMessage, false);
    document.removeEventListener('user-update', this.handleSocketPersonalMessage, false);
    this.props.removeNavbarAction("order-details");

    let order_id = `${this.state.currentOrder.id || ''}`;
    if (order_id !== '') {
      SocketIO.leaveRoom('order-comment', `order-${order_id}`);
    }
  }

  handleSocketPersonalMessage = (e) => { 
    this.loadOrderComments(true);

    console.log('mensaje personal recibido!');
    console.log(e);
  }

  handleSocketMessage = (e) => { 
      this.props.removeToast();
      this.showToast('Nuevo comentario recibido');
      this.loadOrderComments(true);

      console.log('mensaje recibido!');
      console.log(e);
  }

  openOrderDetailDocument = () => {
    let url = `${SERVER}/api/v2/companies/${this.props.companyId}/orders/${this.state.currentOrder?.id ?? 0}/?view=download_locations`;

    this.setState({ 
      downloadingFile: true,
      cancelTokenSource: Axios.CancelToken.source() 
    }, () => {
      this.setNavBar();
      Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        cancelToken: this.state.cancelTokenSource.token,
        headers: this.getToken(),
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.state.selectedOrderCode || 'Orden'}.xlsx`); 
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloadingFile: false, 
          cancelTokenSource: null
        }, () => {
          this.setNavBar();
        })
      });
    })
    
  };

  loadTags = () => {
    this.props.loadTags()
      .then((response) => {
        if (Array.isArray(response.response)) {
          this.setState({
            companyTags: response.response
          });
        }
      })
      .catch((e) => console.log(e))
  }

  loadTrip = () => {

    let order_id = `${this.state.currentOrder.id || ''}`;

    if (order_id !== '') {
      SocketIO.leaveRoom('order-comment', `order-${order_id}`)
    }

    this.props.loadTripInfo(this.props.companyId, this.props.match.params.order, {
      onSuccess: (t) => {
        let currentOrder = t.orders.length ? t.orders[0] : {};
        SocketIO.connectToOrder(`${currentOrder.id || ""}`);

        this.setState({
          selectedorderTags: (currentOrder.tags || []).map(tag => tag.tag),
          selectedTripTags: t.tags.map(t => t.tag),
          selectedClass: t.transport_class.id,
          selectedTrip: t.signature,
          selectedOrderCode: currentOrder.code || "",
          currentOrder: currentOrder,
          trip_id: t.id,
          originTimezone: (((
            this.props.timeZones.find(tZ => tZ.id === ((t.orders[0] || {})
              .from_facility?.time_zone || 0) ) || {})
              .description || '')
              .split('/ ')[1] || '')
              .trim(),
          destinationTimezone: (((
            this.props.timeZones.find(tZ => tZ.id === ((t.orders[0] || {})
            .to_facility?.time_zone || 0)) || {})
            .description || '')
            .split('/ ')[1] || '')
            .trim(),
        }, () => {

          this.setNavBar();
          var orderId = null;

          if (this.props.orderSelected) {

            let ordersToValidate = (this.props.trip.orders || []).map(o => ({ id: o.id, description: o.code }));

            for (var i = 0; i < ordersToValidate.length; i++) {
              if (`${ordersToValidate[i].description}` === `${this.props.orderSelected}`) {
                orderId = ordersToValidate[i].id;
              }
            }

          }

          if (orderId) {
            this.onChange({
              target: {
                name: 'selectedOrderId',
                value: orderId
              }
            });

            if (this.props.commentsVisible) {
              this.setState({ selectedTab: "3" }, () => {
                this.scrollComments();
              });
            }

          } else {
            this.loadOrderHistory();
            this.loadOrderLocations();
            this.loadOrderFacilityGeofences();
            this.loadOrderRouteGeofences();
            this.loadOrderComments(false);
          }

          this.props.removeOrderSeleted();
          this.props.removeOrderCommentsSeleted();

        })
      }
    })
  }

  setRequestsCounter = (orderComments) => {

    let load_pending = 0;
    let load_accepted = 0;
    let load_cancelled = 0;
    let unload_pending = 0;
    let unload_accepted = 0;
    let unload_cancelled = 0;

    let commentsPending = [];

    (orderComments ?? []).map(item => {
      if ((item.payload?.comment ?? '').includes('#SolicitudCambioCitaDescarga')) {
        let thread = item.thread ?? [];
        let added = false;

        thread.map( thread_item => {
          if ((thread_item.comment ?? '').includes('SolicitudCambioCitaDescargaCancelada') && !added) {
            added = true;
            unload_cancelled = unload_cancelled + 1;
          } else if ((thread_item.comment ?? '').includes('SolicitudCambioCitaDescargaAceptada') && !added) {
            added = true;
            unload_accepted = unload_accepted + 1;
          }
        })

        if (!added) {
          unload_pending = unload_pending + 1;
          commentsPending.push(item.microtrip_id);
        }

      } else if ((item.payload?.comment ?? '').includes('#SolicitudCambioCitaCarga')) {
        let thread = item.thread ?? [];
        let added = false;

        thread.map( thread_item => {
          if ((thread_item.comment ?? '').includes('SolicitudCambioCitaCargaCancelada') && !added) {
            added = true;
            load_cancelled = load_cancelled + 1;
          } else if ((thread_item.comment ?? '').includes('SolicitudCambioCitaCargaAceptada') && !added) {
            added = true;
            load_accepted = load_accepted + 1;
          }
        })

        if (!added) {
          load_pending = load_pending + 1;
          commentsPending.push(item.microtrip_id);
        }
      }
    });

    this.setState({
      commentsPending,
      requests: {
        load: {pending: load_pending, accepted: load_accepted, cancelled: load_cancelled},
        unload: {pending: unload_pending, accepted: unload_accepted, cancelled: unload_cancelled}
      }
    })

  }

  loadOrderComments = (scrollToBottom) => {
    this.props.loadOrderComments({
      company_id: this.props.companyId,
      order_id: this.state.currentOrder.id,
    }, {
      onSuccess: (response) => {
        this.setRequestsCounter(response);
        if (scrollToBottom) {
          this.scrollComments();
        }
      }
    })
  }

  loadOrderHistory = () => {
    this.props.loadOrderHistory(this.props.companyId, this.state.currentOrder.id, {
      onSuccess: () => {
        let historyContainers = Object.keys(this.props.orderHistory);
        this.setState({
          historyContainers: historyContainers.map((c, index) => {
            let desc = c.split(',');
            return { id: c, description: "Contenedor ".concat(index + 1), cId: desc[0] }
          }),
          selectedHistoryContainer: historyContainers[0] || "0"
        });
      }
    });
  }

  loadOrderFacilityGeofences = () => {
    let facilities = [];
    if (this.state.currentOrder.from_facility) facilities.push(this.state.currentOrder.from_facility.id);
    if (this.state.currentOrder.to_facility) facilities.push(this.state.currentOrder.to_facility.id);

    this.props.loadOrderFacilityGeofences(this.props.companyId, facilities, {
      onSuccess: () => {
        if (this.mapController.map) {
          this.paintFacilityGeofences();
        } else {
          this.mapController.isReadySubject.subscribe((ready) => {
            if (ready) {
              this.paintFacilityGeofences();
            }
          });
        }
      }
    });
  }

  loadOrderRouteGeofences = () => {
    // let facilities = [];
    // if (this.state.currentOrder.from_facility) facilities.push(this.state.currentOrder.from_facility.id);
    // if (this.state.currentOrder.to_facility) facilities.push(this.state.currentOrder.to_facility.id);

    this.props.loadOrderRouteGeofences({
        company_id: this.props.companyId,
        order_id: this.state.currentOrder.id,
      }, {
        onSuccess: () => {
          if (this.mapController.map) {
            this.paintRouteGeofences();
          } else {
            this.mapController.isReadySubject.subscribe((ready) => {
              if (ready) {
                this.paintRouteGeofences();
              }
            });
          }
        }
      }
    );
  }

  paintRouteGeofences =  () => {
    this.routeGeofences.forEach(gF => {
      gF.setMap(null);
    });
    this.routeGeofences = [];
    this.props.route_geofences.forEach((geofence) => {
      // let latLng = {
      //   lat: route.location[0],
      //   lng: route.location[1],
      // };
      // let marker = this.mapController.setMarkerImage(
      //   // "https://files.dev.v2.orbinetwork.com/images/avatar/icon_factory.svg",
      //   `${files}${(
      //     (this.props.facility_avatars || []).find(
      //       (a) => a.id === route.avatar
      //     ) || { image: "images/avatar/icon_factory.svg" }
      //   ).image
      //   }`,
      //   latLng,
      //   {
      //     width: 27,
      //     height: 25,
      //   }
      // );
      // marker.setMap(this.mapController.map);

      // route.geofences.forEach((gF) => {
        let coord = geofence.polygon;
        coord.pop();
        let pol = this.mapController.createPolygon(
          coord.map((g) => ({ lat: g[0], lng: g[1] })),
          // color
          // (GEOFENCE_TYPES[geofence.zone_type] || { color: color }).color
          "#000000"
        );
        pol.setVisible(true);
        pol.setMap(this.mapController.map);
        this.routeGeofences.push(pol);
      // });
    });
  }

  paintFacilityGeofences = () => {
    this.geoFences.forEach(gF => {
      gF.setMap(null);
    });
    this.geoFences = [];

    this.props.gps_facilities.forEach((facility) => {
      let latLng = {
        lat: facility.location[0],
        lng: facility.location[1],
      };
      let marker = this.mapController.setMarkerImage(
        `${files}${(
          (this.props.facility_avatars || []).find(
            (a) => a.id === facility.avatar
          ) || { image: "images/avatar/icon_factory.svg" }
        ).image
        }`,
        latLng,
        {
          width: 27,
          height: 25,
        }
      );
      marker.setMap(this.mapController.map);

      facility.geofences.forEach((gF) => {
        let coord = gF.poligon[0];
        coord.pop();
        let pol = this.mapController.createPolygon(
          coord.map((g) => ({ lat: g[0], lng: g[1] })),
          // color
          (GEOFENCE_TYPES[gF.type] || { color: color }).color
        );
        pol.setVisible(true);
        pol.setMap(this.mapController.map);
        this.geoFences.push(pol);
      });
    });

    if(this.props.gps_facilities.length){
      if(this.props.gps_facilities.length > 1){
        this.mapController.setCenterMapMultiple(
          this.props.gps_facilities[0].location[0],
          this.props.gps_facilities[0].location[1],
          this.props.gps_facilities[1].location[0],
          this.props.gps_facilities[1].location[1],
          false
        )
      } else {
        this.mapController.setCenterMap({
          lat: this.props.gps_facilities[0].location[0],
          lng: this.props.gps_facilities[0].location[1]
        })
      }
    }
  }

  loadOrderLocations = () => {
    this.props.loadOrderLocations(this.props.companyId, this.state.currentOrder.id, {
      onSuccess: () => {

        let locationContainers = Object.keys(this.props.orderLocation);
        this.setState({
          locationContainers: locationContainers.map(c => ({ id: c, description: "Contenedor ".concat(c) })),
          selectedLocationContainer: locationContainers[0] || "0"
        });
        if (this.mapController.map) {
          this.loadRoute();
        } else {
          this.mapController.isReadySubject.subscribe((ready) => {
            if (ready) {
              this.loadRoute();
            }
          });
        }

      }
    });
  }

  loadRoute = () => {
    let locationContainers = Object.keys(this.props.orderLocation);
    if (this.routeLine) {
      this.routeLine.setMap(null);
    }
    this.state.routeLocations.forEach(e => {
      e.setMap(null);
    });

    this.mapController.hideInfoWindow();

    if (locationContainers.length) {
      let points = this.props.orderLocation[this.state.selectedLocationContainer].locations.map(r => ({
        plates: r.plates, lat: r.location[0], lng: r.location[1], event_date: r.event_date, typeType: 1, descriptions: r.descriptions, device: r.device,
        brand: r.brand, model: r.model, ram: r.ram, imei: r.imei, version: r.version, charging: r.charging, os: r.os, os_version: r.os_version, connected: r.connected
      }));
      let eventPoints = this.props.orderLocation[this.state.selectedLocationContainer].events/*.filter(e => e.device?.device === 1)*/.map(r => ({
        plates: r.plates, lat: r.location[0], lng: r.location[1], event_date: r.event_date, typeType: 2, descriptions: r.descriptions, device: r.device,
        brand: r.brand, model: r.model, ram: r.ram, charging: r.charging, connected: r.connected, os: r.os, os_version: r.os_version,
      }));
      Array.prototype.push.apply(points, eventPoints);
      points.sort(function (a, b) {
        return new Date(b.event_date) - new Date(a.event_date);
      });
      points.reverse();
      let routeDistance = 0;
      points.forEach((point, i, pointArray) => {
        if (i > 0) {
          routeDistance += Math.sqrt(
            Math.pow(
              point.lat - pointArray[i - 1].lat,
              2
            ) +
            Math.pow(
              point.lng - pointArray[i - 1].lng,
              2
            )
          );
        }
      })

      let lastLocation = points.length - 1;
      let routeLocations = points.map((e, i) => {
        let latLng = {
          lat: e.lat,
          lng: e.lng,
        };

        let size, icon;
        if (i === lastLocation) {
          icon = "/images/account_lt_icon.svg";
          size = 70;
        } else {
          icon = "/images/circle.png";
          size = 7;
        }

        let marker;

        if (e.typeType === 1 || size === 70) {
          marker = this.mapController.setMarkerImage(
            icon,
            latLng,
            {
              width: size,
              height: size
            }
          );
        } else {
          marker = this.mapController.setMarkerImage(
            "/images/circleRed.png",
            latLng,
            {
              width: 14,
              height: 14
            }
          );
        }
        marker.setMap(this.mapController.map);
        marker.addListener("click", () => {
          // console.log("click", truck);
          this.showEventInfo(e);
        });
        return marker;
      })

      this.setState({ routePoints: points, routeDistance, routeLocations });

      this.routeLine = new window.google.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 1.0,
        strokeWeight: 2
      });

      this.routeLine.setMap(this.mapController.map);
    }
  }


  showEventInfo = (e) => {
    const content = `<div id='infowindowitem' class='info-window-item' ></div>`;
    const position = new window.google.maps.LatLng(
      e.lat,
      e.lng
    );
    this.mapController.showInfoWindow(content, position, 0, -15, () =>
      this.showEventInfoContent(e)
    );
  }

  showEventInfoContent = (e) => {
    let infoWindow = document.getElementById("infowindowitem");

    ReactDOM.render(
      <div className="event-tooltip-content">
        {(e.descriptions || []).map(d => (<><b>{d}</b><br /></>))}
        <b>Coordenadas: {`${e.lat}, ${e.lng}`}</b><br />
        <b>Placa: {`${e.plates}`}</b><br />
        <b>Hora local evento: {(
          e.event_date 
          && moment(e.event_date)
            .tz(((e.descriptions || []).some(d => d.includes('destino')) ? this.state.destinationTimezone : this.state.originTimezone))
            .format('DD/MMMM HH:mm (UTCZ)') 
          /* this.formatDate(e.event_date) */
          ) || ''
        }</b><br />
        {
          moment.tz.guess() === ((e.descriptions || []).some(d => {/* console.log('MOMENT ZONE', moment().tz()); debugger; */ return d.includes('destino')}) ? this.state.destinationTimezone : this.state.originTimezone)
          ? ''
          : <><b>Hora local usuario: {(
              e.event_date 
              && moment(e.event_date)
                .format('DD/MMMM HH:mm (UTCZ)') 
              /* this.formatDate(e.event_date) */
              ) || ''
            }</b><br /></>
        }
        <b>{e.device?.description}</b><br />
        <b>{e.brand}</b>{e.brand && <br />}
        <b>{e.model}</b>{e.model && <br />}
        {e.ram && <><b> {'RAM: ' + e.ram} </b><br /></>}
        {e.imei && <><b> {'imei: ' + e.imei} </b><br /></>}
        {e.charging !== undefined && <><b> {'cargando: ' + e.charging} </b><br /></>}
        {e.connected !== undefined && <><b> {'conectado: ' + e.connected} </b><br /></>}
        {e.os && <><b> {'os: ' + (`${e.os}` === '1' ? 'Android' : 'iOS')} </b><br /></>}
        {e.os_version && <><b> {'os version: ' + this.getAndroidConvertion(e.os, e.os_version)} </b><br /></>}
        {e.version && <><b> {'app version: ' + e.version} </b><br /></>}
        {/* <b>{e.imei}</b>{e.imei && <br/>} */}
      </div>,
      infoWindow
    );
  }

  getAndroidConvertion = (os, version) => {
    if (`${os}` === '1') {

      var versionToReturn = '';

      switch (`${version}`) {
        case '15': versionToReturn = '4.0'; break;
        case '16': versionToReturn = '4.1'; break;
        case '17': versionToReturn = '4.2'; break;
        case '18': versionToReturn = '4.3'; break;
        case '19': versionToReturn = '4.4'; break;
        case '21': versionToReturn = '5.0'; break;
        case '22': versionToReturn = '5.1'; break;
        case '23': versionToReturn = '6.0'; break;
        case '24': versionToReturn = '7.0'; break;
        case '25': versionToReturn = '7.1'; break;
        case '26': versionToReturn = '8.0'; break;
        case '27': versionToReturn = '8.1'; break;
        case '28': versionToReturn = '9.0'; break;
        case '29': versionToReturn = '10.0'; break;
        case '30': versionToReturn = '11.0'; break;
        case '31': versionToReturn = '12.0'; break;
        default: versionToReturn = version; break;
      }

      return versionToReturn;

    } else {
      return version;
    }
  }


  toggleEditMode = () => {

    if (this.props.companyType === 1 && this.props.trip.base != null && this.props.trip.base?.id != null) {
      this.setState({
        selectedCompanyTag: [this.props.trip.base.id]
      })
    }

    if (this.state.editMode) {
      this.revertChanges();
    }
    this.setState({ editMode: !this.state.editMode });
  }
  saveChanges = () => {
    // let addedTags = this.state.selectedorderTags.filter(t => !this.state.currentOrder.tags.map(tag => tag.tag).includes(t));
    // let removedTags = this.state.currentOrder.tags.filter(t => !this.state.selectedorderTags.includes(t.tag)).map(t => t.id);

    // let addedTripTags = this.state.selectedTripTags.filter(t => !this.props.trip.tags.map(tag => tag.tag).includes(t));
    // let removedTripTags = this.props.trip.tags.filter(t => !this.state.selectedTripTags.includes(t.tag)).map(t => t.id);

    this.setState({
      isLoading: true
    }, () => {
      if (this.props.companyType === 1) {
        this.props.updateTripBase(
          this.props.companyId,
          this.props.trip.id,
          "set_base",
          this.state.selectedCompanyTag.length > 0 ? this.state.selectedCompanyTag[0] : null,
          {
            onSuccess: () => {
              this.loadTrip();
              this.setState({ editMode: false, isLoading: false });
            }
          }
        );
      } else {
        this.props.updateTripControlled(
          {
            company_id: this.props.companyId,
            trip_id: this.props.trip.id,
            trip: {
              id: this.props.trip.id,
              signature: this.state.selectedTrip,
              transport_class: this.state.selectedClass || null,
              trip_tag: this.state.selectedTripTags[0] || null
            },
            order: {
              id: this.state.currentOrder.id,
              order_tag: this.state.selectedorderTags[0] || null,
              code: this.state.selectedOrderCode
            }
          },
          {
            onSuccess: () => {
              this.loadTrip();
              this.setState({ editMode: false, isLoading: false });
            }
          }
        )
      }
    })



    // if(addedTags.length){
    //   addedTags.forEach(t => {
    //     this.props.addOrderTag(
    //       this.props.companyId,
    //       // this.props.match.params.order,
    //       this.state.currentOrder.id,
    //       t,
    //       {
    //         onSuccess: () => {
    //           this.loadTrip();
    //           this.setState({editMode: false});
    //         }
    //       }
    //     )
    //   })
    // } else if(removedTags.length){
    //   removedTags.forEach(t => {
    //     this.props.removeOrderTag(
    //       this.props.companyId,
    //       // this.props.match.params.order,
    //       this.state.currentOrder.id,
    //       t,
    //       {
    //         onSuccess: () => {
    //           this.loadTrip();
    //           this.setState({editMode: false});
    //         }
    //       }
    //     )
    //   })
    // }

    // if(addedTripTags.length){
    //   addedTripTags.forEach(t => {
    //     this.props.addTripTag(
    //       this.props.companyId,
    //       this.props.trip.id,
    //       t,
    //       {
    //         onSuccess: () => {
    //           this.loadTrip();
    //           this.setState({editMode: false});
    //         }
    //       }
    //     )
    //   })
    // } else if(removedTripTags.length){
    //   removedTripTags.forEach(t => {
    //     this.props.removeTripTag(
    //       this.props.companyId,
    //       this.props.trip.id,
    //       t,
    //       {
    //         onSuccess: () => {
    //           this.loadTrip();
    //           this.setState({editMode: false});
    //         }
    //       }
    //     )
    //   })
    // }

    // this.props.updateOrder(
    //   this.props.companyId,
    //   // this.props.match.params.order,
    //   this.state.currentOrder.id,
    //   this.state.selectedOrderCode,
    //   {
    //     onSuccess: () => {
    //       this.loadTrip();
    //       this.setState({editMode: false});
    //     }
    //   }
    // )

    // this.props.updateTrip(
    //   this.props.companyId,
    //   this.props.trip.id,
    //   this.state.selectedTrip,
    //   this.state.selectedClass,
    //   {
    //     onSuccess: () => {
    //       this.loadTrip();
    //       this.setState({editMode: false});
    //     }
    //   }
    // )
  }

  revertChanges = () => {
    this.setState({
      selectedCompanyTag: [],
      selectedorderTags: this.state.currentOrder ? this.state.currentOrder.tags.map(tag => tag.tag) : [],
      selectedTripTags: this.props.trip.tags.map(t => this.props.trip.tag),
      selectedClass: this.props.trip.transport_class.id,
      selectedTrip: this.props.trip.signature,
      selectedOrderCode: this.state.currentOrder ? this.state.currentOrder.code : ""
    })
  }

  cancelChanges = () => {
    this.revertChanges();
    this.toggleEditMode();
  }

  onChange = (event) => {
    let property = event.target.name;
    let value = event.target.value;
    if (property === "selectedOrderId" && this.props.trip.orders !== undefined) {

      let previous_order_id = `${this.state.currentOrder.id || ''}`;
      if (previous_order_id !== '') {
        SocketIO.leaveRoom('order-comment', `order-${previous_order_id}`); 
      }

      let currentO = this.props.trip.orders.find(o => o.id.toString() === value.toString()) || {}

      let order_id = `${currentO.id || ""}`;
      SocketIO.connectToOrder(order_id);

      this.setState({
        currentOrder: currentO,
        selectedOrderCode: currentO.code,
        selectedorderTags: currentO.tags.map(t => t.tag),
        originTimezone: (((this.props.timeZones
          .find(tZ => tZ.id === (currentO.from_facility?.time_zone || 0)) || {})
          .description || '')
          .split('/ ')[1] || '')
          .trim(),
        destinationTimezone: (((this.props.timeZones
          .find(tZ => tZ.id === (currentO.to_facility?.time_zone || 0)) || {})
          .description || '')
          .split('/ ')[1] || '')
          .trim(),
      }, () => { this.loadOrderHistory(); this.loadOrderLocations(); this.loadOrderFacilityGeofences(); this.loadOrderRouteGeofences(); this.loadOrderComments(true); this.setNavBar();})
    } else if (property === "editOrderTrip") {
      this.setState({ [property]: value })
      switch (event.target.id) {
        case "current":
        case "new":
          if (value.length > 5) {
            this.props.searchSignature(
              this.props.companyId,
              value,
              {
                onSuccess: (response) => {
                  if (response?.count > 0) {
                    this.setState({ errorEditOrderTrip: true, msgEditOrderTrip: "Ya existe un viaje con ese número" });
                  } else {
                    this.setState({ errorEditOrderTrip: false, msgEditOrderTrip: "" });
                  }
                }
              }
            );
          } else {
            this.setState({ errorEditOrderTrip: false, msgEditOrderTrip: "" });
          }
          break;
        case "change":
          if (value.length > 5) {
            this.props.findOrderTrip(
              this.props.companyId,
              this.state.currentOrder.id,
              value,
              {
                onSuccess: (response) => {
                  var list = [];
                  var candidateSelectedToChange = -1;
                  if (response.length > 0) {
                    list = response.map((r) => {
                      return { 
                        id: r.id, 
                        title: r.signature, 
                        color: r.is_active == true ? "3D77F7" : "cccccc", 
                        isActive: r.is_active, 
                        isFull: true,
                      }
                    })
                    if (response.length === 1) {
                      candidateSelectedToChange = response[0].id
                    }
                  }

                  this.setState({ candidatesTripToChange: list, selectedToChangeTripId: candidateSelectedToChange })
                }
              }
            );
          } else {
            this.setState({ candidatesTripToChange: [] });
          }
          break
        default:
          this.setState({ [property]: value })
          break;
      }
    } else {
      this.setState({ [property]: value })
    }
  }

  handleTagClick = (e) => {
    // if (e.target.value) this.setState({ selectedorderTags: this.state.selectedorderTags.filter(t => t !== e.target.id) });
    // else this.setState({ selectedorderTags: this.state.selectedorderTags.concat([e.target.id]) });
    if (this.props.companyType === 1) {
      if (e.target.tag.type === 3) {
        if (e.target.value) this.setState({ selectedCompanyTag: [] });
        else this.setState({ selectedCompanyTag: [e.target.id] });
      }
    } else {
      if (e.target.tag.type === 7) {
        if (e.target.value) this.setState({ selectedorderTags: [] });
        else this.setState({ selectedorderTags: [e.target.id] });
      }
      if (e.target.tag.type === 9) {
        if (e.target.value) this.setState({ selectedTripTags: [] });
        else this.setState({ selectedTripTags: [e.target.id] });
      }
    }
  };

  switchTab = (e) => {
    let val = e.target.attributes["name"].value;
    this.setState({ selectedTab: val }, () => {
      if (val === "2" && this.state.routePoints.length) {
        let lats = this.state.routePoints.map(p => p.lat);
        let lngs = this.state.routePoints.map(p => p.lng);
        this.mapController.setCenterMapMultiple(
          Math.min(...lats),
          Math.min(...lngs),
          Math.max(...lats),
          Math.max(...lngs)
        )
      } else if (val === "3") {
        this.scrollComments();
      }

    });
  }

  scrollComments = () => {
    let objDiv = document.getElementById("commentsContainer");
    objDiv.scrollTop = objDiv.scrollHeight;

    let mainObjDiv = document.getElementById("pageContent");
    mainObjDiv.scrollTop = mainObjDiv.scrollHeight;
  }

  switchLocTab = (e) => {
    let val = e.target.attributes["name"].value;
    this.setState({ selectedLocationContainer: val }, () => {
      this.loadRoute();
      // let lats = this.state.routePoints.map(p => p.lat);
      // let lngs = this.state.routePoints.map(p => p.lng);
      // this.mapController.setCenterMapMultiple(
      //   Math.min(...lats),
      //   Math.min(...lngs),
      //   Math.max(...lats),
      //   Math.max(...lngs)
      // )
    });
  }

  switchHistTab = (e) => {
    let val = e.target.attributes["name"].value;
    this.setState({ selectedHistoryContainer: val });
  }

  formatDate = (date) => {
    if (!date) {
      return "-"
    }

    let d = new Date(date);

    let mm = d.getMonth();
    let dd = this.fillZero(d.getDate());
    let MM = this.fillZero(d.getMinutes());
    let hh = this.fillZero(d.getHours());

    return dd.toString().concat('/', MONTHS[mm], " ", hh, ":", MM);
  }

  fillZero = (time) => {
    return time < 10 ? '0'.concat(time.toString()) : time;
  }

  togglePlay = () => {
    if (this.state.isPlaying) {
      this.pauseRouteAnimation();
    } else {
      this.drawRouteAnimation();
    }

    this.setState({ isPlaying: !this.state.isPlaying });
  }

  //this.state.routeDistance

  drawRouteAnimation = () => {
    this.routeTimeline.duration = Math.min(
      this.state.speed,//Math.round(this.state.routeDistance * 50000),
      this.state.speed
    );
    // debugger;
    if (this.routeTimeline.status !== "PAUSE") {
      // this.routeTimeline.position = 0;
      this.routeTimeline.setPosition(0);
      this.routeTimeline.direction = 1;
    }

    this.routeTimeline.play();
  }

  pauseRouteAnimation = () => {
    // debugger;
    this.routeTimeline.pause();
  };

  updateRouteAnimation = () => {
    let positions = this.state.routePoints;
    let length = 0;
    let drawLength =
      this.state.routeDistance *
      this.routeTimeline.getTime(0, this.routeTimeline.duration);
    let points = [];
    let addToMap = 0;
    // console.log(positions);
    // debugger;
    // return;
    for (let iRoute = 0; iRoute < positions.length - 1; iRoute++) {
      let latLng = new window.google.maps.LatLng(
        positions[iRoute].lat,
        positions[iRoute].lng
      );
      points.push(latLng);

      if (iRoute > 0) {
        let distance = Math.sqrt(
          Math.pow(positions[iRoute].lat - positions[iRoute - 1].lat, 2) +
          Math.pow(positions[iRoute].lng - positions[iRoute - 1].lng, 2)
        );
        if (length + distance > drawLength) {
          points.pop();

          let diff = drawLength - length;
          let angle = Math.atan2(
            positions[iRoute].lat - positions[iRoute - 1].lat,
            positions[iRoute].lng - positions[iRoute - 1].lng
          );
          let lat = Number(positions[iRoute - 1].lat) + diff * Math.sin(angle);
          let long = Number(positions[iRoute - 1].lng) + diff * Math.cos(angle);

          let latLng = new window.google.maps.LatLng(lat, long);
          points.push(latLng);
          /**/
          break;
        }
        length += distance;
      }

      addToMap = iRoute;
    }

    for (let iRoute = 0; iRoute < positions.length - 1; iRoute++) {
      let map = iRoute <= addToMap ? this.mapController.map : null;
      if (this.state.routeLocations[iRoute].getMap() !== map) {
        this.state.routeLocations[iRoute].setMap(map);
      }
    }

    //routeDetailPanel239796
    this.routeLine.setPath(points);
    console.log(this.routeTimeline.duration, this.routeTimeline.getPosition());
    if (this.routeTimeline.duration === this.routeTimeline.getPosition()) {
      // debugger;
      this.setState({ isPlaying: !this.state.isPlaying });
    }
  };

  handleTextChange = (e) => {
    // debugger;
    this.setState({ [e.target.name]: e.target.value });
  }

  sendFav = () => {
    if (
      (this.state.favMsg === -1 && !this.state.selectedComment) ||
      (this.state.favReply === -1 && this.state.selectedComment)
    ) {
      this.props.createFavoriteComment({
        company_id: this.props.companyId,
        company_user: this.props.companyUser.id,
        comment: this.state.selectedComment ? this.state.newFavReply : this.state.newFav
      }, {
        strategy: "IGNORE",
        onSuccess: () => {
          this.props.loadFavoriteComments({
            company_id: this.props.companyId,
            company_user: this.props.companyUser.id,
          });
          this.setState({
            showFavComments: false,
            newFav: "",
            currentComment: this.state.newFav,
            showFavReplies: false,
            newFavReply: "",
            currentReply: this.state.newFavReply
          })
        }
      })
    } else {
      this.setState({
        showFavComments: false,
        showFavReplies: false,
        newFav: "",
        newFavReply: "",
        currentComment: this.state.selectedComment
          ? ""
          : (
            this.props.favComments.find(fC => fC.id === this.state.favMsg)
            || {}
          ).comment,
        currentReply: this.state.selectedComment
          ? (
            this.props.favComments.find(fC => fC.id === this.state.favReply)
            || {}
          ).comment
          : "",
        favMsg: -1,
        favReply: -1
      })
    }
  }

  sendCommentAppointment = (message, users, shortMessage) => { 
    // let order_room = `order-${this.state.currentOrder.id || ""}`;
    // SocketIO.sendMessage('order-comment', order_room, this.state.currentComment);

    this.setAppointmentDate(); 

    let params = {
      company_id: this.props.companyId,
      order_id: this.state.currentOrder.id,
      order: this.state.currentOrder.id,
      url: null,
      document_type: 0,
      comment: message,
      users_tagged: (users || []).map(item => item.id).join(','),
      appointment_message: message,
      appointment_short_message: shortMessage,
      notification_message: message
    };

    this.props.setActionAppointmentRequest(
      {
        company_id: this.props.companyId,
        order_id: this.state.currentOrder.id,
        action: 'cancel_change_appointment_request'
      }, 
      {
        onSuccess: (result) => {
          
          if (result && result['cancelation'] && result['cancelation']['users'] && result['cancelation']['users'].length > 0) {  
            sendMessageToMultipleUsersInSocket(
              this.props.userId,
              result['cancelation']['users'],
              result['cancelation']['data']
            );
          }

          this.sendCommentWs(params, () => {
            if (this.props.isCargoOwner) {
              this.setAcceptedResponse();
            }
            this.loadOrderComments(true);
            this.setState({ currentComment: "", commentFiles: [], currentReply: "", replyFiles: [], selectedComment: 0 });
          });

        },

        onError: () => {
          this.sendCommentWs(params, () => {
            if (this.props.isCargoOwner) {
              this.setAcceptedResponse();
            }
            this.loadOrderComments(true);
            this.setState({ currentComment: "", commentFiles: [], currentReply: "", replyFiles: [], selectedComment: 0 });
          });
        }
      }
    )
  }

  sendComment = (args) => {
    let order_room = `order-${this.state.currentOrder.id || ""}`;
    SocketIO.sendMessage('order-comment', order_room, this.state.currentComment);

    if (args.message) {
      this.state.currentComment = args.message;
    }

    if (args.reply_message) {
      this.state.currentReply = args.reply_message;
    }

    if (
      (this.state.currentComment === "" && !this.state.commentFiles.length && !this.state.selectedComment)
      || (this.state.currentReply === "" && !this.state.replyFiles.length && this.state.selectedComment)
    ) return null;
    let files = this.state.selectedComment ? this.state.replyFiles : this.state.commentFiles;

    let document_type = 0;
    if (files.length > 0) {
      if (files[0].type === "application/pdf") {
        document_type = 2;
      } else if (files[0].type.includes("image")) {
        document_type = 1;
      }
    }

    let params = {
      company_id: this.props.companyId,
      order_id: this.state.currentOrder.id,
      order: this.state.currentOrder.id,
      url: files.length ? files[0] : null,
      document_type: document_type,
      comment: this.state.selectedComment ? this.state.currentReply : this.state.currentComment,
      notification_message: this.state.selectedComment ? this.state.currentReply : this.state.currentComment,
      users_tagged: (args.users && args.users.length > 0 ? args.users : []).join(',')
    };

    if (this.state.selectedComment) {
      params.microtrip_parent = this.state.selectedComment;
    }
    
    this.sendCommentWs(params, () => {
      this.loadOrderComments(this.state.selectedComment === 0);
      this.setState({ currentComment: "", commentFiles: [], currentReply: "", replyFiles: [], selectedComment: 0 });
    });

  }

  sendCommentWs = (params, success_action) => {

    this.props.createOrderComment(params, {
      alias: 'comment_creation',
      onSuccess: (result) => {

        if (result && result['configuration'] && result['configuration']['users'] && result['configuration']['users'].length > 0) {
          sendMessageToMultipleUsersInSocket(
            this.props.userId,
            result['configuration']['users'],
            result['configuration']['data']
          );
        }

        if (result && result['tagged'] && result['tagged']['users'] && result['tagged']['users'].length > 0) {
          sendMessageToMultipleUsersInSocket(
            this.props.userId,
            result['tagged']['users'],
            result['tagged']['data']
          );
        }

        if (result && result['comment'] && result['comment']['users'] && result['comment']['users'].length > 0) {
          sendMessageToMultipleUsersInSocket(
            this.props.userId,
            result['comment']['users'],
            result['comment']['data']
          );
        }

        if (success_action) {
          success_action();
        }

      },

      onError: () => {
        this.setState({
          errorMessageModal: 'Ocurrió un error al enviar el comentario, intente de nuevo',
          currentComment: "", 
          commentFiles: [], 
          currentReply: "", 
          replyFiles: [], 
          selectedComment: 0
        })
      },
      isForm: true,
    });

  }

  sendCommentRequest = (params, update_orders) => {

    let order_room = `order-${this.state.currentOrder.id || ""}`;
    SocketIO.sendMessage('order-comment', order_room, this.state.currentComment);
    
    this.props.createOrderComment(params, {
      alias: 'comment_creation',
      onSuccess: () => {
        if (update_orders) { this.loadOrderComments(this.state.selectedComment === 0); }
      },
      isForm: true,
    });
  }

  handleAddDocument = (e) => {
    // const filesNumber = e.target.value.length /* + this.props.value.files.length */;

    // if (filesNumber > 3) {
    //   this.props.showLimitFiles();
    // } else {
    // let value = (this.props.value[e.target.name] || []).concat(
    // let value = [e.target.value]
    // );
    // this.handleInput({ target: { name: e.target.name, value: value } });
    if (this.state.selectedComment) {
      this.setState({ replyFiles: e.target.value });
    } else {
      this.setState({ commentFiles: e.target.value });
    }
    // }
  };

  openDocumentUrlImage = (url) => {
    if (!url) return
    window.open(getUrlsEnv().files.concat(url), "_blank");
  };

  downloadDocument = (url, id) => {
    if (!url) return

    this.state.downloadingFileComments[id] = true;
    this.setState({});

    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();

        this.state.downloadingFileComments[id] = false;
        this.setState({});
      });
    }).catch((response) => {
      this.state.downloadingFileComments[id] = false;
      this.setState({});
    });
  };

  getCoordsUrl = () => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/orders/${this.state.currentOrder.id}/?view=rpt_placas_viaje`;
  };

  openCoordsDocument = (url) => {
    Axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Coordenadas.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  handleRemoveDocument = () => {
    this.setState({ commentFiles: [], replyFiles: [] });
  };

  getAssembly = (r) => {
    return (
      <div>
        {r.assembly_data
          ? (
            r.assembly_data?.fleet_vehicle_list?.length > 0 ||
            (r.assembly_data?.fleet_equipment_list || []).length > 0 ||
            (r.assembly_data?.fleet_operator_list || []).length > 0
          )
            ? (
              <ReactTooltip id={`order-assemble-${r.id}`}>
                {
                  (r.assembly_data?.fleet_vehicle_list || [])
                    .map((e) => (
                      <AssembleAvatar
                        fleetTypeId={1}
                        description={`${e.economic_number || ""} - ${e.plates || ""}`}
                        //  color?: string,
                        showIcon={true}
                      //  icon?: any,
                      />
                    ))
                }
                {(r.assembly_data?.fleet_equipment_list || [])
                  .map((e) => (
                    <AssembleAvatar
                      fleetTypeId={2}
                      description={`${e.economic_number || ""} - ${e.plates || ""}`}
                      //  color?: string,
                      showIcon={true}
                    //  icon?: any,
                    />
                  ))}
                {(r.assembly_data?.fleet_operator_list || [])
                  .map((e) => (
                    <AssembleAvatar
                      fleetTypeId={3}
                      description={`${e.operator?.user?.first_name || ""} ${e.operator?.user?.last_name || ""}`}
                      //  color?: string,
                      showIcon={true}
                    //  icon?: any,
                    />
                  ))}
              </ReactTooltip>
            )
            : ""
          : ""
        }
        <div data-place="right" data-tip data-for={`order-assemble-${r.id}`} style={{ display: "flex", alignItems: "center" }}>
          {
            r.assembly_data && (
              r.assembly_data?.fleet_vehicle_list?.length
                ? (r.assembly_data.fleet_equipment_list || []).length
                  ? (r.assembly_data.fleet_equipment_list || []).length > 1
                    ? <TruckDoubleEquipment />
                    : <TruckEquipment />
                  : <Truck />
                : (r.assembly_data.fleet_equipment_list || []).length
                  ? (r.assembly_data.fleet_equipment_list || []).length > 1
                    ? <DoubleEquipment />
                    : <Equipment />
                  : <></>)
          }
          {"   "}
          {
            r.assembly_data
            && ((r.assembly_data.fleet_vehicle_list || []).length
              ? (
                <div>{
                  (r.assembly_data.fleet_vehicle_list || [])
                    .flat()
                    .map((e) => e.economic_number)
                }</div>
              )
              : "-")
          }
        </div>
      </div>
    );
  }

  deleteEvent = () => {
    this.props.deleteOrderEvent({
      company_id: this.props.companyId,
      order_id: this.state.currentOrder.id,
      microtrip_id: this.state.selectedEvent.microtrip_id,
      eliminate_all: this.state.deleteForAllContainers,
    }, {
      onSuccess: () => {
        this.props.setOrderDetail(this.state.currentOrder.code);

        this.loadTrip();
        this.setState({
          showDeleteEventModal: false,
          selectedEvent: null,
          deleteForAllContainers: true
        })
      },
      onError: (e) => {
        let err = (e.response?.data?.errors || []).join(', ');
        this.setState({errorMessageModal: err, showDeleteEventModal: false});
      }
    })
  }

  handleChange = (e) => {
    if (this.state.selectedComment) {
      switch (e.target.name) {
        case "showFavComments":
          this.setState({ showFavReplies: e.target.value, newFavReply: "", favReply: -1 });
          break;
        case "currentComment":
          this.setState({ currentReply: e.target.value });
          break;
        case "newFav":
          this.setState({ newFavReply: e.target.value });
          break;
        case "favMsg":
          this.setState({ favReply: e.target.value });
          break;
        default:
          this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      if (e.target.name === "showFavComments") {
        this.setState({ newFav: "", favMsg: -1 })
      }
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  deleteFavoriteComment = (id) => {
    this.props.deleteFavoriteComment({
      company_id: this.props.companyId,
      company_user: this.props.companyUser.id,
      comment_id: id
    }, {
      onSuccess: () => {
        this.props.loadFavoriteComments({
          company_id: this.props.companyId,
          company_user: this.props.companyUser.id,
        });
      }
    }
    )
  }

  setModelEditOrder = () => {
    // console.log(this.state.optionEditOrderModal);
    switch (this.state.optionEditOrderModal) {
      case "numberTrip":
        this.setState({
          selectedTrip: this.state.editOrderTrip
        }, () => {
          this.props.updateTripControlled(
            {
              company_id: this.props.companyId,
              trip_id: this.props.trip.id,
              trip: {
                id: this.props.trip.id,
                signature: this.state.selectedTrip,
                transport_class: this.state.selectedClass || null,
                trip_tag: this.state.selectedTripTags[0] || null
              },
              order: {
                id: this.state.currentOrder.id,
                order_tag: this.state.selectedorderTags[0] || null,
                code: this.state.selectedOrderCode
              }
            },
            {
              onSuccess: () => {
                this.loadTrip();
                this.setState({ 
                  editMode: false,
                  isLoading: false,
                  showEditOrderModal: false,
                  editOrderTrip: "",
                  errorEditOrderTrip: false, 
                  msgEditOrderTrip: "", 
                  candidatesTripToChange: [],
                  selectedToChangeTripId: -1
                });
              },
              onError: () => {
                this.loadTrip();
                this.setState({
                  editMode: false,
                  isLoading: false,
                  showEditOrderModal: false,
                  editOrderTrip: "",
                  errorEditOrderTrip: false, 
                  msgEditOrderTrip: "", 
                  candidatesTripToChange: [],
                  selectedToChangeTripId: -1,
                  showErrorEditOrderModal: true,
                  titleErrorEditOrderMdal: "No pudimos editar el número de viaje"
                });
              }
            }
          )
        })
        return;
      case "toExistingTrip":
        this.setState({optionEditOrderModal: "toExistingTripConfirmation"});
        break;
      case "toExistingTripConfirmation":
        this.props.transferOrderTrip(
          this.props.companyId,
          this.state.currentOrder.id,
          this.state.selectedToChangeTripId,
          {
            onSuccess: () => {
              this.loadTrip();
              this.setState({
                editMode: false,
                isLoading: false,
                showEditOrderModal: false,
                editOrderTrip: "",
                errorEditOrderTrip: false, 
                msgEditOrderTrip: "", 
                candidatesTripToChange: [],
                selectedToChangeTripId: -1
              });
            },
            onError: () => {
              this.loadTrip();
              this.setState({
                editMode: false,
                isLoading: false,
                showEditOrderModal: false,
                editOrderTrip: "",
                errorEditOrderTrip: false, 
                msgEditOrderTrip: "", 
                candidatesTripToChange: [],
                selectedToChangeTripId: -1,
                showErrorEditOrderModal: true,
                titleErrorEditOrderMdal: "No pudimos vincular la orden al nuevo viaje"
              });
            }
          }
        );
        return;
      case "toNewTrip":
        this.setState({optionEditOrderModal: "toNewTripConfirmation"});
        break;
      case "toNewTripConfirmation":
        this.props.newOrderTrip(
          this.props.companyId,
          this.state.currentOrder.id,
          this.state.editOrderTrip,
          {
            onSuccess: () => {
              this.loadTrip();
              this.setState({
                editMode: false,
                isLoading: false,
                showEditOrderModal: false,
                editOrderTrip: "",
                errorEditOrderTrip: false, 
                msgEditOrderTrip: "", 
                candidatesTripToChange: [],
                selectedToChangeTripId: -1
              });
            },
            onError: () => {
              this.loadTrip();
              this.setState({
                editMode: false,
                isLoading: false,
                showEditOrderModal: false,
                editOrderTrip: "",
                errorEditOrderTrip: false, 
                msgEditOrderTrip: "", 
                candidatesTripToChange: [],
                selectedToChangeTripId: -1,
                showErrorEditOrderModal: true,
                titleErrorEditOrderMdal: "No pudimos crear el nuevo viaje"
              });
            }
          }
        );
        return;
    }
  }

  candidatesToChips = () => {
    return this.state.candidatesTripToChange.map((t) => ({
      id: t.id,
      title: t.title,
      color: t.color,
      isFull: t.isFull,
      isActive: t.isActive
    }));
  };

  getModelEditOrder = () => {
    switch (this.state.optionEditOrderModal) {
      case "numberTrip":
        return (
          <>
            <span className="param">N° actual: </span> {this.props.trip.signature}
            <TextInput
              type='text'
              label=''
              name='editOrderTrip'
              id='current'
              className=''
              value={this.state.editOrderTrip.length == 0 ? this.state.seelectedTrip : this.state.editOrderTrip}
              onChange={this.onChange}
            />
            {this.state.errorEditOrderTrip &&
              <p style={{ color: "red", margin: "0 0 0 10px" }}>{this.state.msgEditOrderTrip}</p>
            }
          </>
        );
      case "toExistingTrip":
        return (
          <>
            <div>Busca el viaje</div>
            <TextInput
              type='text'
              label=''
              name='editOrderTrip'
              id='change'
              className=''
              value={this.state.editOrderTrip}
              onChange={this.onChange}
            />
            <div>
              <ChipContainer
                items={this.candidatesToChips()}
                title=""
                name="candidates"
                background="dark"
                onChange={ (e) => {
                  if(e.target.tag?.isActive){
                    if (e.target.tag.title.length > 5) {
                      this.setState({ editOrderTrip: e.target.tag.title })
                      this.props.findOrderTrip(
                        this.props.companyId,
                        this.state.currentOrder.id,
                        e.target.tag.title,
                        {
                          onSuccess: (response) => {
                            var list = [];
                            var candidateSelectedToChange = -1;
                            if (response.length > 0) {
                              list = response.map((r) => {
                                return { 
                                  id: r.id, 
                                  title: r.signature, 
                                  color: r.is_active == true ? "3D77F7" : "cccccc", 
                                  isActive: r.is_active, 
                                  isFull: true 
                                }
                              })
                              if (response.length === 1) {
                                candidateSelectedToChange = response[0].id
                              }
                            }
          
                            this.setState({ candidatesTripToChange: list, selectedToChangeTripId: candidateSelectedToChange })
                          }
                        }
                      );
                    } else {
                      this.setState({ candidatesTripToChange: [] });
                    }
                  }
                }}
              />
            </div>
            {this.state.errorEditOrderTrip &&
              <p style={{ color: "red", margin: "0 0 0 10px" }}>{this.state.msgEditOrderTrip}</p>
            }
          </>
        )
      case "toExistingTripConfirmation":
        return (
          <div style={{fontSize: "14px"}}>
            Se eliminarán los eventos de viaje anterior de esta orden y adoptará los eventos y elementos del viaje nuevo, incluidos clase de expedición y compañía transportista.
          </div>
        )
      case "toNewTrip":
        return (
          <>
            <div></div>
            <TextInput
              type='text'
              label=''
              name='editOrderTrip'
              id='new'
              className=''
              value={this.state.editOrderTrip}
              onChange={this.onChange}
            />
            {this.state.errorEditOrderTrip &&
              <p style={{ color: "red", margin: "0 0 0 10px" }}>{this.state.msgEditOrderTrip}</p>
            }
          </>
        )
      case 'toNewTripConfirmation':
        return (
          <div style={{fontSize: "14px"}}>
            Se eliminarán los eventos del viaje anterior y se guardará como un viaje nuevo y sin compañía transportista asignada (viaje nuevo sin reservación).
          </div>
        )
      default:
        return <div />;
    }
  };

  getEtaLoading = (order_containers, appointment) => {

    let timesToShow = [];
  
    (order_containers || []).map( container => {
      
      let ataDate = container?.ata_loading ?? '';
      let etaDate = container?.eta_loading ?? '';
  
      let timeToValidate = ataDate === '' ? etaDate : ataDate;
  
      let isEta = ataDate === '';
  
      if (timeToValidate === '') {
        timesToShow.push(
          <div style={{marginLeft: 5}}>{`ETA: No disponible`}</div>
        );
      } else {
  
        let isRedColor = false;
  
        if (appointment !== '') {
          isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
        }

        let eta_loading_data = container.eta_loading_data ?? null;

        timesToShow.push(
          <>
            <ReactTooltip
              id={`container-eta-load-${container.id}`}
              disable={!isEta || eta_loading_data === null}
              clickable={true}
              event="click"
            >
              <div id={'container-eta'}>{`Creación de ETA: ${`${moment(eta_loading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}`}</div>
              <div id={'container-eta'} style={{display: 'flex',flexDirection: 'row'}}>
                <div id={'container-eta'}>{'Coordenada:'}</div>
                <div
                  id={'container-eta'}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: 5
                  }}
                  onClick={() => {
                    let points = (eta_loading_data?.from ?? '').split(',');
                    if (points.length > 1) {
                      window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                    }
                  }}
                >{` ${eta_loading_data?.from ?? ''}`}</div>
              </div>
              <div id={'container-eta'}>{`Distancia: ${eta_loading_data?.distance ?? ''} kms`}</div>
              <div id={'container-eta'}>{`Placa ETA: ${eta_loading_data?.plates ?? ''}`}</div>
              <div style={{maxWidth: 350}}id={'container-eta'}>{`Placas Ensamble: ${ (eta_loading_data?.plates_history ?? []).join(', ')}`}</div>
              <div id={'container-eta'}>{`Dispositivo: ${eta_loading_data?.device ?? ''}`}</div>
            </ReactTooltip>
            
            <div 
              style={{display: 'flex', flexDirection: 'row'}} data-place="right" data-tip data-for={`container-eta-load-${container.id}`}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
            >
              <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
              <div style={{color: isRedColor ? 'red' : 'white'}}>
                {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
              </div>
            </div>
          </>
        );
  
      }
  
    });
  
    return(
      <div> 
        { timesToShow }
      </div>
    );
  
  }

  getEtaUnloading = (order_containers, appointment) => {

    let timesToShow = [];
  
    (order_containers || []).map( container => {
      
      let ataDate = container?.ata_unloading ?? '';
      let etaDate = container?.eta_unloading ?? '';
  
      let timeToValidate = ataDate === '' ? etaDate : ataDate;
  
      let isEta = ataDate === '';
  
      if (timeToValidate === '') {
        timesToShow.push(
          <div style={{marginLeft: 5}}>{`ETA: No disponible`}</div>
        );
      } else {
  
        let isRedColor = false;
  
        if (appointment !== '') {
          isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
        }

        let eta_unloading_data = container.eta_unloading_data ?? null;
        
        timesToShow.push(
          <>
            <ReactTooltip
              id={`container-eta-unload-${container.id}`}
              disable={!isEta || eta_unloading_data === null}
              clickable={true}
              event="click"
            >
              <div id={'container-eta'} >{`Creación de ETA: ${`${moment(eta_unloading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}`}</div>
              <div id={'container-eta'} style={{display: 'flex',flexDirection: 'row'}}>
                <div id={'container-eta'}>{'Coordenada:'}</div>
                <div
                  id={'container-eta'}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: 5
                  }}
                  onClick={() => {
                    let points = (eta_unloading_data?.from ?? '').split(',');
                    if (points.length > 1) {
                      window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                    }
                  }}
                >{` ${eta_unloading_data?.from ?? ''}`}</div>
              </div>
              <div id={'container-eta'}>{`Distancia: ${eta_unloading_data?.distance ?? ''} kms`}</div>
              <div id={'container-eta'}>{`Placa ETA: ${eta_unloading_data?.plates ?? ''}`}</div>
              <div style={{maxWidth: 350}} id={'container-eta'}>{`Placas Ensamble: ${ (eta_unloading_data?.plates_history ?? []).join(', ')}`}</div>
              <div id={'container-eta'}>{`Dispositivo: ${eta_unloading_data?.device ?? ''}`}</div>

            </ReactTooltip>

            <div 
              style={{display: 'flex', flexDirection: 'row'}} data-place="right" data-tip data-for={`container-eta-unload-${container.id}`}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
            >
              <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
              <div style={{color: isRedColor ? 'red' : 'white'}}>
                {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
              </div>
            </div>
          </>
        );
  
      }
  
    });
  
    return(
      <div id={'loading-eta'}> 
        { timesToShow }
      </div>
    );
  
  }

  getEstimatedTime = (eta, ata, appointment) => {
    let timeToValidate = ata === '' ? eta : ata;
  
    if (timeToValidate === '') {
  
      return(
        <div style={{display: 'flex', flexDirection: 'row', fontSize: 14}}>
          <div style={{color: '#afc8ff'}}>{'ETA: '}</div>
          <div style={{marginLeft: 5}}>{'No disponible'}</div>
        </div>
      );
  
    } else {
  
      let isEta = ata === ''
  
      if (appointment !== '') {
  
        let isRedColor = moment(timeToValidate).isSameOrAfter(appointment);

        return(
          <div style={{display: 'flex', flexDirection: 'row', fontSize: 14}}>
            <div style={{color: '#afc8ff'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
            <div style={{marginLeft: 5, color: isRedColor ? 'red' : 'white'}}>{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
          </div>
        );
  
      } else {

        return(
          <div style={{display: 'flex', flexDirection: 'row', fontSize: 14}}>
            <div style={{color: '#afc8ff'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
            <div style={{marginLeft: 5, color: 'white'}}>{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
          </div>
        );
  
      }
  
    }
  
  }

  unhookContainer = () => {
    this.props.unhookContainer({
      company_id: this.props.companyId,
      order_id: this.state.currentOrder.id,
      container_id: this.state.selectedHistoryContainer
    }, {
      onSuccess: () => {
        this.setState({showUnhookModal: false});
        this.loadTrip();
      }
    })
  }

  hookContainer = () => {
      this.props.hookContainer({
        company_id: this.props.companyId,
        order_id: this.state.currentOrder.id,
        container_id: this.state.selectedHistoryContainer
      }, {
        onSuccess: () => {
          this.setState({showHookModal: false});
          this.loadTrip();
        }
      })
  }

  transformComment = (comment) => {

    let componentsToShow = [];
    let commentArray = comment.split(' ');

    commentArray.map( item => {
      if (item.includes('#') || item.includes('@')) {
        componentsToShow.push(<div style={{color: 'blue'}}>{`${item}`}</div>);
      } else {
        componentsToShow.push(`${item}`);
      }
      componentsToShow.push(<div style={{width: 6}}/>);
    });
    //28002106

    return(
      <div style={{display: 'flex'}}> {componentsToShow} </div>
    );

  }

  getCommentsContent = () => {

    if (this.props.isLoadingComments && this.state.selectedTab === "0") {
      return(
        <div style={{height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 7, marginTop: 20, color: 'black'}}>
          {'Cargando'}
          <div style={{width: 8}}/>
          <Loader circleColor={'black'}/>
        </div>
      );
    }

    return(
      <div className={"comments-tab tab-body ".concat(this.state.selectedTab !== "3" ? "hidden" : "")}>

        {
          (this.props.orderComments ?? []).length > 0 &&
          <div className="header">
            <div>{'Comentarios'}</div>
          </div>
        }

        <div className="comments-container" id="commentsContainer">
          { 
            Array.isArray(this.props.orderComments) && this.props.orderComments.sort((a,b) => a.microtrip_id - b.microtrip_id).map(comment => {
              let user = {};
              if (comment.user) {
                user.name = comment.user.user || '-';
                if (comment.user.profile_image) {
                  user.src = getUrlsEnv().files.concat(comment.user.profile_image);
                  user.width = "35px";
                } else {
                  user.src = "/images/menu/user-no-circle.svg";
                  user.width = "29px";
                }
              } else {
                user.name = "Sistema";
                user.src = "/images/alert-icon-red.svg";
                user.width = "29px";
                user.className = "alert"
              }
              return (
                <>
                  <div 
                    className="comment section" 
                    style={{
                      backgroundColor: this.state.commentsPending.includes(comment.microtrip_id) ? '#EBEEF0' : 'white'
                    }}
                  >
                    <div style={{height: 20}}/>
                    <div className="avatar-container">
                      <img
                        className={user.className || ""}
                        src={user.src}
                        alt=""
                        width={user.width}
                      />
                    </div>
                    <div className="comment-info">
                      <div className="user-name">
                        {user.name}
                      </div>
                      <div className="divider"></div>
                      <div className="date-info">
                        {this.formatDate(comment.created)}
                      </div>
                    </div>
                    <div className="comment-container">
                      {
                        comment.payload?.comment ? (this.transformComment(comment.payload?.comment || '-')) : ''
                      }
                      {
                        comment.payload?.url && (comment.payload?.url || '') !== 'null'
                          ? 
                          <ChipImageContent>
                            <Chip
                              // key={img.id}
                              id={comment.payload?.payload_id}
                              active={true}
                              className="white small"
                              name="files"
                              description={"Archivo"}
                              onClick={() => this.openDocumentUrlImage(comment.payload?.url)}
                              prefix={{
                                element: (
                                  <SVGIcon
                                    name={icons.imageFile}
                                    fill="#657795"
                                    viewBox="0 -1 16 16"
                                  />
                                ),
                              }}
                            />
                            <DownloadButtonContainer>
                              {
                                (this.isDownloadingFile(`${comment.payload?.payload_id || 0}`)) ? <Loader width="25px" height="25px" circleColor={'black'}/> :
                                <DownloadButtonContainer 
                                  onClick={() => this.downloadDocument(comment.payload?.url, `${comment.payload?.payload_id || 0}`)}
                                >
                                  <DownloadImageContent 
                                    alt=""
                                    src={"/images/download_icon.png"}
                                  />
                                </DownloadButtonContainer>
                              }
                            </DownloadButtonContainer>
                          </ChipImageContent> : ''
                      }
                    </div>

                    {
                      (comment.thread || []).length > 0 &&
                      <div style={{height: 20}}/>
                    }

                    {
                      comment.thread && comment.thread.map(t => {
                        return (<div className="comment secondary">
                          <div className="avatar-container">
                            <img
                              className={t.user?.profile_image ? "user-avatar" : ""}
                              src={t.user?.profile_image
                                ? getUrlsEnv().files.concat(t.user.profile_image)
                                : "/images/menu/user-no-circle.svg"
                              }
                              alt=""
                              width={t.user?.profile_image ? "35px" : "29px"}
                            />
                          </div>
                          <div className="comment-info">
                            <div className="user-name">
                              {t.user?.user || '-'}
                            </div>
                            <div className="divider"></div>
                            <div className="date-info">
                              {this.formatDate(t.event_date)}
                            </div>
                          </div>
                          <div className="comment-container">
                            {
                              t.comment ? (this.transformComment(t.comment || '-')) : ''
                            }
                            {
                              t.url && t.url !== 'null'
                                ? 
                                <ChipImageContent>
                                  <Chip
                                    // key={img.id}
                                    id={t.payload_id}
                                    active={true}
                                    className="white small"
                                    name="files"
                                    description={"Archivo"}
                                    onClick={() => this.openDocumentUrlImage(t.url)}
                                    prefix={{
                                      element: (
                                        <SVGIcon
                                          name={icons.imageFile}
                                          fill="#657795"
                                          viewBox="0 -1 16 16"
                                        />
                                      ),
                                    }}
                                  />
                                  <DownloadButtonContainer>
                                    {
                                      (this.isDownloadingFile(`${t.payload_id}`)) ? <Loader width="25px" height="25px" circleColor={'black'}/> :
                                      <DownloadButtonContainer 
                                        onClick={() => this.downloadDocument(t.url, `${t.payload_id}`)}
                                      >
                                        <DownloadImageContent 
                                          alt=""
                                          src={"/images/download_icon.png"}
                                        />
                                      </DownloadButtonContainer>
                                    }
                                  </DownloadButtonContainer>
                                </ChipImageContent>
                                : ''
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                    
                    <div
                      className="comment-options"
                      onClick={() => {
                        this.setState({
                          selectedComment: this.state.selectedComment === comment.microtrip_id ? 0 : comment.microtrip_id,
                          replyFiles: [],
                          showFavReplies: false,
                          currentReply: "",
                          newFavReply: "",
                          favReply: -1
                        })
                      }}
                    >
                      <div className="divider"></div>
                      Responder
                    </div>

                    {
                    (this.state.selectedComment === comment.microtrip_id) &&
                      <div
                        style={{
                          margin: '40px 20px -40px -100px'
                        }}
                      >
                        <CommentInput
                          order_id={this.state.currentOrder.id || ''}
                          isCargoOwner={this.props.isCargoOwner}
                          showFavComments={this.state.showFavReplies}
                          favComments={this.props.favComments}
                          favMsg={this.state.favReply}
                          newFav={this.state.newFavReply}
                          currentComment={this.state.currentReply}
                          commentFiles={this.state.replyFiles}
                          userData={this.props.userData}
                          handleChange={this.handleChange}
                          deleteFavoriteComment={this.deleteFavoriteComment}
                          sendFav={this.sendFav}
                          handleRemoveDocument={this.handleRemoveDocument}
                          handleAddDocument={this.handleAddDocument}
                          sendComment={this.sendComment}
                          requests={this.state.requests}
                        />
                      </div>
                    }
                    
                  </div>

                </>
              )
            })
          }
        </div>
        <CommentInput
          isAbleToChangeAppointment={!this.props.isCargoOwner || this.isAbleToChangeAppointment()}
          order_id={this.state.currentOrder.id || ''}
          newConversation
          isCargoOwner={this.props.isCargoOwner}
          showFavComments={this.state.showFavComments}
          favComments={this.props.favComments}
          favMsg={this.state.favMsg}
          newFav={this.state.newFav}
          currentComment={this.state.currentComment}
          commentFiles={this.state.commentFiles}
          userData={this.props.userData}
          handleChange={this.handleChange}
          deleteFavoriteComment={this.deleteFavoriteComment}
          sendFav={this.sendFav}
          handleRemoveDocument={this.handleRemoveDocument}
          handleAddDocument={this.handleAddDocument}
          sendComment={this.sendComment}
          infoData={this.state.appointmentChangeData}
          sendCommentAppointment={this.sendCommentAppointment}
          requests={this.state.requests}
          showAssignAppointment={ (isLoad) => {
            this.setState({
              shownewAppointmentModal: true,
              manueverToAssign: isLoad ? 'Carga' : 'Descarga'
            });
          }}
          resetInfo={() => this.setState({appointmentChangeData: null})}
        />
      </div>
    
    );

  }

  isDownloadingFile = (id) => {
    return (id in this.state.downloadingFileComments && this.state.downloadingFileComments[id]);
  }

  openDocument = (url) => {
    window.open(url, "_blank");
  };

  getColor = (url) => {
    // url.includes('xml3') ? "#3d77f7" : "#dcdcdc"
    if(url.includes('3>')){
      return "#3d77f7";
    }
    if(url.includes('4>') || url.includes('5>') || url.includes('6>')){
      return "#dc1111"
    }
    return "#dcdcdc"
  }

  validateTimeZone = (description) => {
    const wordsToValidate = ['destino', 'descarga terminada', 'descargando', 'viaje completado', 'orden entregada', 'entrada de inventario', 'descarga modificada', 'descarga cancelada', 'descarga asignada'];
    let isIncluded = false;

    wordsToValidate.map( item => {
      if (description.toLowerCase().includes(item)) {
        isIncluded = true;
      }
    });

    return isIncluded;
  }

  //################# RE ORDER - EVENTS ##############

  reorderItems = (items_) => {

    let items_removed = items_.filter( item => item.invisible === true )
    let items_not_removed = items_.filter( item => item.invisible === false )

    let items = items_removed.concat(items_not_removed);

    const orderList = [
      {id: 1, owner: "order_requisition", isEventData: false},
      {id: 2, owner: "order_requisition", isEventData: false},
      {id: 3, owner: "order_requisition", isEventData: false},
      {id: 4, owner: "order_requisition", isEventData: false},
      {id: 1, owner: "order_delivery", isEventData: true},
      {id: 2, owner: "order_delivery", isEventData: true},
      {id: 3, owner: "order_delivery", isEventData: true},
      {id: 4, owner: "order_delivery", isEventData: true},
      {id: 5, owner: "order_delivery", isEventData: true},
      {id: 1, owner: "order", isEventData: false},
      {id: 2, owner: "order", isEventData: false},
      {id: 10, owner: "order", isEventData: false},
      {id: 3, owner: "order", isEventData: false},
      {id: 21, owner: "order", isEventData: true},
      {id: 4, owner: "order", isEventData: false},
      {id: 9, owner: "trip", isEventData: true},
      {id: 5, owner: "order", isEventData: false},
      {id: 6, owner: "order", isEventData: false},
      {id: 3, owner: "trip", isEventData: false},
      {id: 4, owner: "trip", isEventData: false},
      {id: 21, owner: "container", isEventData: false},
      {id: 1, owner: "container", isEventData: false},
      {id: 19, owner: "order", isEventData: true},
      {id: 7, owner: "order", isEventData: false},
      {id: 8, owner: "trip", isEventData: false},
      {id: 1, owner: "trip", isEventData: false},
      {id: 13, owner: "trip", isEventData: true},
      {id: 2, owner: "trip", isEventData: false},
      {id: 10, owner: "trip", isEventData: false},
      {id: 5, owner: "trip", isEventData: false},
      // {id: 21, owner: "container", isEventData: false},
      {id: 2, owner: "container", isEventData: false}, 
      {id: 22, owner: "container", isEventData: false},
      {id: 3, owner: "container", isEventData: false},
      {id: 4, owner: "container", isEventData: false},
      {id: 5, owner: "container", isEventData: false},
      {id: 6, owner: "container", isEventData: false},
      {id: 7, owner: "container", isEventData: false},
      {id: 11, owner: "container", isEventData: false},
      {id: 12, owner: "container", isEventData: false},
      {id: 13, owner: "container", isEventData: false},
      {id: 14, owner: "container", isEventData: false},
      {id: 15, owner: "container", isEventData: false},
      {id: 20, owner: "container", isEventData: false},
      {id: 9, owner: "order", isEventData: false},
      {id: 11, owner: "order", isEventData: false},
      {id: 7, owner: "trip", isEventData: false},
      {id: 9, owner: "trip", isEventData: false},
      {id: 8, owner: "order", isEventData: false},
      {id: 6, owner: "trip", isEventData: false},
      {id: 9, owner: "container", isEventData: false},
      {id: 8, owner: "container", isEventData: false},
      {id: 1, owner: "trip", isEventData: true},
      {id: 5, owner: "trip", isEventData: true},
      {id: 2, owner: "trip", isEventData: true},
      {id: 3, owner: "trip", isEventData: true},
      {id: 6, owner: "trip", isEventData: true},
      {id: 4, owner: "trip", isEventData: true},
      {id: 19, owner: "order", isEventData: true},
      {id: 20, owner: "order", isEventData: true},
      {id: 3, owner: "order", isEventData: true},
      {id: 1, owner: "order", isEventData: true},
      {id: 4, owner: "order", isEventData: true},
      {id: 2, owner: "order", isEventData: true},
      {id: 5, owner: "order", isEventData: true},
      {id: 6, owner: "order", isEventData: true},
      // {id: 2, owner: "container", isEventData: false},
      // {id: 7, owner: "order", isEventData: false},
      // {id: 5, owner: "trip", isEventData: false},
    ];

    let newOrder = [];

    orderList.map( order => {

      items.map( item => {

        let itemInserted = newOrder.filter(o => o.microtrip_id === item.microtrip_id).length > 0;

        if (item.owner === order.owner && item.id === order.id && order.isEventData === item.is_event_data && !itemInserted) 
          newOrder.push(item);
      });

    });

    let others = [];

    items.map( item => {
      if (newOrder.filter(o => o.microtrip_id === item.microtrip_id).length === 0) {
        others.push(item);
      }
    });

    return newOrder.concat(others);
  }

  buildHistoricalList = () => {

    let items = (this.props.orderHistory[this.state.selectedHistoryContainer] || []);
    let transferEventsDate = [];

    items.forEach(item => {

      if (item.is_event_data && item.id === 15) {
        transferEventsDate.push(item.created);
      }

    });

    let datePivot = "";
    let newItemsToReturn = [];
  
    let itemsToOrder = [];
  
    items.map( (item, index) => {
  
      let ownerEvent = item.owner || "";

      item.isTransferEvent = false;

      if (transferEventsDate !== null && !(item.is_event_data && item.id === 15)) {

        transferEventsDate.forEach( transfer_item => {
          if (this.compareBetweenDates(transfer_item, item.created)) {
            item.isTransferEvent = true;
          }
        });
        
      }
      
      if (["order", "trip", "container", "order_delivery", "order_requisition"].includes(ownerEvent) && datePivot === "") datePivot = item.event_date || "";
  
      if (datePivot !== "") {
  
        if (this.compareDates(datePivot, item.event_date)) {
          itemsToOrder.push(item);
  
          if (index === items.length - 1) {
            let newOrder = this.reorderItems(itemsToOrder);
            newItemsToReturn = newOrder.concat(newItemsToReturn);
            itemsToOrder = [];
          }
  
        } else {
  
          let newOrder = [];
  
          if (itemsToOrder.length > 0) {
            newOrder = this.reorderItems(itemsToOrder);
            itemsToOrder = [];
          }
  
          if (["order", "trip", "container", "order_delivery", "order_requisition"].includes(ownerEvent)) {
            datePivot = item.event_date || "";

            itemsToOrder.push(item);

            if (index === items.length - 1) {
              newOrder = this.reorderItems(itemsToOrder.concat(newOrder));
            }

          } else {
            newItemsToReturn.push(item);
            datePivot = "";
          }
  
          newItemsToReturn = newOrder.concat(newItemsToReturn);
  
        }
  
      } else {
        newItemsToReturn.push(item);
      }
  
    });
  
    return newItemsToReturn.reverse();
  }

  compareBetweenDates = (dateString1, dateString2) => {
    let date1 = new Date(dateString1);
    let date2 = new Date(dateString2);
    let date3 = new Date(dateString1);
    let date4 = new Date(dateString1);

    date1.setSeconds(0);
    date2.setSeconds(0);
    date3.setSeconds(0);
    date4.setSeconds(0);
    date1.setMilliseconds(0);
    date2.setMilliseconds(0);
    date3.setMilliseconds(0);
    date4.setMilliseconds(0);

    date3.setTime(date1.getTime() - 1*60*1000);
    date4.setTime(date1.getTime() + 1*60*1000);

    return date2.getTime() <= date4.getTime() && date2.getTime() >= date3.getTime();

  }

  compareDates = (dateString1, dateString2) => {
    let date1 = new Date(dateString1);
    let date2 = new Date(dateString2);

    date1.setSeconds(0);
    date2.setSeconds(0);
    date1.setMilliseconds(0);
    date2.setMilliseconds(0);
    
    return date2.getTime() === date1.getTime();
  }

  getHistoryContent = () => {

    if (this.props.isLoadingHistory && this.state.selectedTab === "1") {
      return(
        <div style={{height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 7, marginTop: 20, color: 'black'}}>
          {'Cargando'}
          <div style={{width: 8}}/>
          <Loader circleColor={'black'}/>
        </div>
      );
    }

    return(
      <div className={"history tab-body ".concat(this.state.selectedTab !== "1" ? "hidden" : "")}>
        <div className="header">
          Historial Orden
        </div>
        <div className="container-ribbon">
          <TabSelectorRibbon 
            activeId={this.state.selectedHistoryContainer}
            items={this.state.historyContainers}
            onChange={this.switchHistTab}
            selectedUnderlineColor={"#45b2ff"}
            textColor="#000"
            bgColor="#fff"
          />
          <div className="header-actions">
          {
            this.props.companyType === 1 && (this.state.currentOrder?.status?.id || 0) === 7 && this.numberOfEquipments() > 1 && ((this.state.currentOrder?.containers || []).find(c => c.id.toString() === (this.state.selectedHistoryContainer || '').split(',')[0]) || {status: {id: 0}}).status.id !== 9 &&
            <Button
              type={"primary"}
              text={"Desenganchar"}
              onClick={() => this.setState({showUnhookModal: true})}
            />
          }
          {
            this.props.companyType === 1 && ((this.state.currentOrder?.containers || []).find(c => c.id.toString() === (this.state.selectedHistoryContainer || '').split(',')[0]) || {status: {id: 0}}).status.id === 9 &&
            <Button
              type={"primary"}
              text={"Enganchar"}
              onClick={() => this.setState({showHookModal: true})}
            />
          }
          </div>
        </div>
        <div className="history-rows">
          {
            (this.buildHistoricalList()).map((event, index) => {
              // let eventInfo = event.container_status || event.order_status || event.trip_status || {};
              let classname_op = "history-row".concat(index % 2 ? ' w' : ' b')
              if (event.invisible) {
                classname_op = "history-row i"
              }
              return (
                <div 
                  className={classname_op}
                  style={{
                    borderLeft: `${event.isTransferEvent ? '0.4rem' : '0.0rem'} rgb(78, 152, 247) solid`
                  }}  
                >
                  <div className="owner-icon">
                    {
                      event.owner === "trip"
                        ? <Unitary />
                        : event.owner === "container" && event.device.device !== 1
                          ? <LocationPin />
                          : event.owner === "container"
                            ? <img src="/images/mobile-icon.svg" alt="" />
                            : ["order", "order_delivery", "order_requisition"].includes(event.owner)
                              ? <img src="/images/order-page-icon.svg" alt="" />
                              : ""
                    }
                  </div>
                  <div>
                    <div>{event.description}</div>
                    <div className="user-info-container" style={{ display: "initial" }}>
                      {/* {event.extra_info?.join(', ').split('<br>').map(eI => (<div>{eI}</div>))} */}
                      {(event.extra_info || []).filter(item => !item.includes('Zona horaria')).map((eI, i, a) => {
                        switch (eI) {
                          case "<vehicle>":
                            return <Truck />;
                          case "<dolly>":
                            return <Dolly />;
                          case "<equipment>":
                            return <Equipment />;
                          case "<operator>":
                            return <Operator />;
                          case "<br>":
                            return <br />;
                          default:
                            let content = '';
                            if(eI.includes('<pdf')){
                              content = <span
                                style={{ cursor: "pointer"}} 
                                onClick={() => {
                                  this.openDocument(eI.slice(eI.indexOf(">")+1));
                                }}
                              >
                                <PDFIcon 
                                  height="23" 
                                  width="23"
                                  fill={this.getColor(eI)}
                                />
                              </span>
                              // content = <img
                              //       alt=""
                              //       src="/images/pdf_icon.png"
                              //       style={{ width: '17px', height: '20px', cursor: "pointer", borderRadius: "0"}}
                              //       onClick={() => {
                              //         this.openDocument(eI.slice(eI.indexOf(">")+1));
                              //       }}
                              //     />
                            } else if(eI.includes('<xml')){
                              content = <span
                                style={{ cursor: "pointer"}} 
                                onClick={() => {
                                  this.openDocument(eI.slice(eI.indexOf(">")+1));
                                }}
                              >
                                <XMLIcon  
                                  height="23" 
                                  width="23"
                                  fill={this.getColor(eI)}
                                />
                              </span>
                              // content = <img
                              //       alt=""
                              //       src="/images/xml_icon.png"
                              //       style={{ width: '17px', height: '20px', cursor: "pointer", borderRadius: "0"}}
                              //       onClick={() => {
                              //         this.openDocument(eI.slice(5));
                              //       }}
                              //     />
                            } else {
                              content = eI;
                            }
                            return <span>{content}{((i < a.length - 1) && (a[i + 1] !== "<br>")) ? ", " : ""}</span>;
                        }
                      })
                      }
                    </div>
                    {
                      event.user ?
                        <div className="user-info-container">
                          <img
                            src={(event.user?.profile_image)
                              ? getUrlsEnv().files.concat(event.user?.profile_image)
                              : "/images/interrogacion.svg"}
                            alt=""
                          />
                          <div style={{display: 'flex', fontSize: '12'}}>
                            {event.user?.first_name} {event.user?.last_name} - <i>{event.user?.username} </i>

                            <div style={{display: 'flex'}}>
                              {'   -   Hora de inserción:'}
                              <div style={{width: 2}}/>
                              {
                                (event.created 
                                  && moment(event.created)
                                    .tz(event.time_zone ? event.time_zone : (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone))
                                    .format('DD/MMM HH:mm') 
                                ) || '-'
                              }
                              <div style={{marginLeft: 8, marginRight: 2}}>
                                {'Zona horaria:'}
                              </div>
                              { event.time_zone ? event.time_zone : (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone) }
                            </div>
                          </div>
                        </div>
                        :
                        <div style={{display: 'flex', fontSize: 12, fontWeight: 'normal'}}>
                          {
                            event.device.device === 3 ? 'System  -  Hora de inserción:' : 'Hora de inserción:'
                          }

                          <div style={{width: 2}}/>
                          {
                            (event.created 
                              && moment(event.created)
                                .tz(event.time_zone ? event.time_zone : (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone))
                                .format('DD/MMM HH:mm') 
                            ) || '-'
                          }
                          <div style={{marginLeft: 8, marginRight: 2}}>
                            {'Zona horaria:'}
                          </div>
                          { event.time_zone ? event.time_zone : (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone)}
                        </div>
                    }
                    {

                      event.elimination_user ?

                        <div className="user-info-container">
                          
                          {(event.elimination_user?.id ?? null) !== null &&
                            <div>
                              <img
                                src={(event.elimination_user?.profile_image)
                                  ? getUrlsEnv().files.concat(event.elimination_user?.profile_image)
                                  : "/images/interrogacion.svg"}
                                alt=""
                              />
                            </div>
                          }
                          
                          {(event.elimination_user?.id ?? null) === null ?
                            <div>
                              <i> {event.elimination_user?.username} </i>
                            </div> :
                            <div>
                              {event.elimination_user?.first_name} {event.elimination_user?.last_name} - <i>{event.elimination_user?.username} </i>
                            </div>
                          } 
                          <div>
                            <i> >>> Evento eliminado - {this.buildTimeZoneFormat({'created': event.elimination_user.elimination_event_date, 'time_zone': event.time_zone, 'description': event.description })} </i>
                          </div>
                        </div>
                        :
                        ''
                    }
                  </div>
                  <div>
                    {
                      (event.event_date 
                        && moment(event.event_date)
                          .tz((this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone))
                          .format('DD/MMM HH:mm (UTCZ)') 
                          /* this.formatDate(event.event_date) */
                      ) || '-'
                    }
                    
                    <div style={{fontSize: 13, fontWeight: 'normal'}}>
                      { (this.validateTimeZone(event.description) ? this.state.destinationTimezone : this.state.originTimezone)}
                    </div>

                  </div>
                  {//eliminar eventos
                    this.props.companyType === 1
                    ? 
                      (
                        event.deletable //validacion back
                        && (
                            !event.description.toLowerCase().includes("sin datos") //excluir los finalizados sin datos
                            || event.device.device === 5 //excepto los que sean por usuarios
                            || event.device.device === 3 //excepto los que sean por system
                            || event.device.device === 8 //excepto los que sean por OrderFinalizerJob
                          ) 
                        && this.props.companyType === 1 //solo cuando la compania sea LT
                      ) ? <img
                            className="delete-icon"
                            alt=""
                            src="/images/bin.png"
                            onClick={() => {
                              this.setState({ showDeleteEventModal: true, selectedEvent: event })
                            }}
                          />  
                        : ''
                    : (
                        event.deletable //validacion back
                        && event.description.toLowerCase().includes("sin datos") //excluir los finalizados sin datos
                        && (
                            event.device.device === 5 //excepto los que sean por usuarios
                            || event.device.device === 3 //excepto los que sean por system)
                            || event.device.device === 8 //excepto los que sean por OrderFinalizerJob)
                          ) 
                      ) ? <img
                            className="delete-icon"
                            alt=""
                            src="/images/bin.png"
                            onClick={() => {
                              this.setState({ showDeleteEventModal: true, selectedEvent: event })
                            }}
                          />  
                        : ''
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }

  numberOfEquipments = () => {
    if(!this.state.currentOrder.assembly_data) return 0;
    return (this.state.currentOrder.assembly_data.fleet_equipment_list || []).filter(e => e.fleet_class.id !== 21 && e.fleet_class.id !== 22).length
  }

  setAppointmentDate = () => {
    if (this.props.isCargoOwner && this.state.appointmentChangeData && this.state.appointmentChangeData.params) {
      this.props.setAppointmentDate(this.state.appointmentChangeData.params);
    }
  }

  setAcceptedResponse = () => {
    this.props.setOrderDetail(`${this.state.currentOrder.code}`);

    this.props.setActionAppointmentRequest(
      {
        company_id: this.props.companyId,
        order_id: this.state.currentOrder.id,
        action: 'accept_change_appointment_request'
      }, 
      {
        onSuccess: (result) => {
          if (result && result['accepted'] && result['accepted']['users'] && result['accepted']['users'].length > 0) {
            sendMessageToMultipleUsersInSocket(
              this.props.userId,
              result['accepted']['users'],
              result['accepted']['data']
            );
          }
        }
      }
    )

    this.loadTrip();
  }

  getLoaderView = () => {

    if (this.props.isNotificationLoader) {
      return(
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000080",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1001,
            backdropFilter: "blur(3px)"
          }}
        >
          <div style={{display: "flex", alignItems: "center"}}>
            <Loader width="100px" height="100px"/>
            <div style={{color: "#fff", fontSize: "36px"}}>
              Cargando
            </div>
          </div>
        </div>
      );
    }

    return(<></>)
  }

  isAbleToChangeAppointment = () => {
    return this.props.isCargoOwner && this.validatePerm(61, 'u') && ![6,7].includes(this.props.trip?.status?.id || 0);
  }

  validatePerm = (perm, type) => {
    return validateObjPerms(
      [ 
        { id: String(perm), perm: String(type)}
      ],
      true
    ) === true;
  };

  mainContent = () => {

    const { trip } = this.props;
    const { currentOrder } = this.state;  

    let id = currentOrder?.status?.id;
    let isAvailableEditing = id === 3 || id === 4 || id === 5 || id === 6 || id === 7;

    let lastChangeDateUtc = '';
    let lastChangeDate = '';
    let symbolToShow = ''

    if (this.state.currentOrder.last_change) {
      lastChangeDateUtc = moment.utc(this.state.currentOrder?.last_change ?? '').format("DD/MMM/YYYY HH:mm");
      lastChangeDate = moment(this.state.currentOrder?.last_change ?? '').format("DD/MMM/YYYY HH:mm");

      let offset = moment(this.state.currentOrder?.last_change ?? '').utcOffset();
      symbolToShow = offset === 0 ? '' : `${offset / 60}`
    }

    let actions = [
      {
        description: "Editar el número de viaje",
        action: () => { this.setState({ showEditOrderModal: true, optionEditOrderModal: "numberTrip", titleEditOrderMdal: "Editar número de viaje" }) }
      },
      isAvailableEditing && {
        description: "Vincular orden a viaje existente",
        action: () => { this.setState({ showEditOrderModal: true, optionEditOrderModal: "toExistingTrip", titleEditOrderMdal: "Vincular a viaje existente" }) }
      },
      isAvailableEditing && {
        description: "Vincular orden a viaje nuevo",
        action: () => { this.setState({ showEditOrderModal: true, optionEditOrderModal: "toNewTrip", titleEditOrderMdal: "Vincular a viaje nuevo" }) }
      }
    ];

    return (
      <View className="order-detail-view">
        {this.getLoaderView()}
        <ViewTitle>
          <div className="data">Orden
            {this.state.editMode
              ? <TextInput
                type='text'
                label=''
                name='selectedOrderCode'
                className=''
                value={this.state.selectedOrderCode}
                onChange={this.props.companyType === 2 ? this.onChange : () => { }}
              />
              : <SelectInput
                label=""
                name="selectedOrderId"
                value={this.state.currentOrder.id || 0}
                className="order"
                onChange={this.onChange}
                items={(this.props.trip.orders || []).map(o => ({ id: o.id, description: o.code }))}
              />
            }{/* <span className="order-number">n°{currentOrder.code}</span> */}
          </div>
          <div className="order-detail-main-status">
            <span className="param">Estatus:</span> {currentOrder.status?.description}
          </div>
        </ViewTitle>
        <div className="panel">
          {//Viaje completado
            (
              currentOrder.status?.description
              && (
                currentOrder.status.description.toLowerCase() !== "finalizado sin datos"
                && currentOrder.status.description.toLowerCase() !== "viaje completado"
                && currentOrder.status.description.toLowerCase() !== "cancelado"
              )
            ) && <div
              className="edit-info edit-btn"
              onClick={this.toggleEditMode}
            >
              {this.props.companyType === 1 ? <img alt="" src="/images/edit-transparent.svg" /> :
                // <ValidatePerms
                //   perms={[
                //     {
                //       id: "61",
                //       perm: "u",
                //     },
                //   ]}
                // >
                  <img alt="" src="/images/edit-transparent.svg" />
                // </ValidatePerms>
              }
            </div>
          }
          <div className="trip">
            <div className="trip-stop">
              <div className="avatar-container">
                <ImageWithCheckMark
                  src={getUrlsEnv().files.concat(
                    (currentOrder.from_company || {}).logo
                  )}
                  width={80}
                  isCheckMark={(currentOrder.from_company || {}).verification_status === 3}
                />
              </div>
              <div className="credentials">
                <div className="location">
                  <img
                    className="location-pin"
                    src={"/images/location-pin.svg"}
                    alt=""
                  />
                  {(currentOrder.from_facility || {}).name}
                </div>
                <div className="param">Fecha de recolección:</div>
                <div
                  className="event-date"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  {currentOrder.appointment_loading_date !== null ? getLastSession(currentOrder.appointment_loading_date, 'LOG_FORMAT') : 'Sin cita'}
                  { (this.isAbleToChangeAppointment()) &&  
                    <div
                      style={{cursor: 'pointer', marginLeft: 8, width: 'fit-content'}}
                      onClick={() => {
                        this.setState({
                          showEditAppointmentModal: true,
                          manueverToAssign: 'Carga'
                        });
                      }}
                    >
                      <img alt="" src="/images/edit-transparent.svg" />
                    </div>
                  }
                </div>
                {
                  this.getEtaLoading(currentOrder.containers || [], currentOrder.appointment_loading_date || '')
                }
              </div>
            </div>
            <div className="transit-line">
              <div className="arrow-base"></div>
              <img
                className="arrow-body"
                src={"/images/arrow.svg"}
                alt=""
              />
              <div className="arrow-end"></div>
            </div>
            <div className="trip-stop">
              <div className="avatar-container">
                <ImageWithCheckMark
                  src={getUrlsEnv().files.concat(
                    (currentOrder.to_company || {}).logo
                  )}
                  width={80}
                  isCheckMark={(currentOrder.to_company || {}).verification_status === 3}
                />
              </div>
              <div className="credentials">
                <div className="location">
                  <img
                    className="location-pin"
                    src={"/images/location-pin.svg"}
                    alt=""
                  />
                  {(currentOrder.to_facility || {}).name}
                </div>
                <div className="param">Fecha de recolección:</div>
                <div 
                  className="event-date"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  { currentOrder.appointment_unload_date !== null ? getLastSession(currentOrder.appointment_unload_date, 'LOG_FORMAT') : 'Sin cita'}
                  { (this.isAbleToChangeAppointment()) && 
                    <div
                      style={{cursor: 'pointer', marginLeft: 8, width: 'fit-content'}}
                      onClick={() => {
                        this.setState({
                          showEditAppointmentModal: true,
                          manueverToAssign: 'Descarga'
                        });
                      }}
                    >
                      <img alt="" src="/images/edit-transparent.svg" />
                    </div>
                  }
                </div>
                {
                  this.getEtaUnloading(currentOrder.containers || [], currentOrder.appointment_unload_date || '')
                }
              </div>
            </div>
          </div>
          <div className="details">
            <div className="info">
              <div className="left">
                <div>
                  <span className="param">Tipo de orden:</span>
                  {"Venta"}
                </div>
                <div>
                  <span className="param">Transporte:</span>
                  {(trip.transport_type || {}).description}
                </div>
                <div className="data">
                  <span className="param">Clase:</span>
                  {this.state.editMode && this.props.companyType === 2
                    ? <SelectInput
                      label=""
                      name="selectedClass"
                      value={this.state.selectedClass || 0}
                      className="item"
                      onChange={this.onChange}
                      // items={this.props.companyTransportClasses.map(c => ({id: c.id, description: c.code}))}
                      items={this.props.transportClassesByTag}
                      grouped={true}
                    />
                    : (trip.transport_class && trip.transport_class.tags)
                    && <div style={{ lineHeight: "15px" }}>
                      <GroupContainer title={`${trip.transport_class.tags.tag__title}:`}>
                        <StyledTag
                          id={trip.transport_class.id}
                          title={trip.transport_class.code}
                          onClick={() => { }}
                        />
                      </GroupContainer>
                    </div>
                  }
                </div>
                {
                  (this.state.editMode && this.props.companyType === 2 &&
                    this.props.trip?.transport_class &&
                    this.props.trip?.transport_class.equipment_quantity !== ((this.props.companyTransportClasses || []).find(tC => tC.id.toString() === this.state.selectedClass.toString()) || {}).equipment_quantity) &&
                  <div
                    style={{ lineHeight: "12px", fontSize: "12px", width: "560px", color: "#fb2b2b" }}
                  >
                    *La nueva clase difiere en idcs con la clase original.
                    Este cambio reiniciará todas las órdenes del viaje y se perderá toda la información generada hasta el momento y realizará ajustes en los cobros de créditos.
                  </div>
                }
                <div style={{ display: "flex", }}>
                  <span className="param">Ensamble:</span>
                  {this.getAssembly(currentOrder)}
                </div>
              </div>
              <div className="right">
                <div
                  style={{display: 'flex'}}
                >
                  <span className="param">Transportista:</span>
                  <ImageWithCheckMark
                    src={(trip.fleet_provider && trip.fleet_provider.logo)
                      ? getUrlsEnv().files.concat(
                        (trip.fleet_provider || {}).logo
                      )
                      : "/images/interrogacion.svg"}
                    width={30}
                    isCheckMark={(trip.fleet_provider || {}).verification_status === 3}
                    customStyle={{
                      width: 30, height: 30, borderRadius: '50%', position: 'relative', marginRight: 10
                    }}
                  />
                  {trip.fleet_provider?.alias || trip.fleet_provider?.orbi_id || 'Pendiente'}
                </div>
                <div className="data">
                  <span className="param">N° de viaje:</span>
                  {trip.signature}
                  {!this.state.editMode && this.props.companyType === 2 && /*(
                    currentOrder.status?.id === 3 || currentOrder.status?.id === 4 || currentOrder.status?.id === 5 || 
                    currentOrder.status?.id === 6 || currentOrder.status?.id === 7
                  ) && */
                    <div style={{ margin: "6px 0px 6px 10px" }}>
                      <ActionsTricolon
                        tooltipPlacement={"bottom"}
                        actions={actions}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="tags">
              {
                this.props.companyType === 1
                  ? <>
                    <div>
                      <div className="param">
                        Base:
                      </div>
                      <div className="tags-container">
                        {
                          this.state.editMode
                            ? (this.state.companyTags || []).map(tag => {
                              return <Tag
                                id={tag.id}
                                color={tag.color}
                                title={tag.title}
                                type={tag.type}
                                isFull={this.state.selectedCompanyTag.includes(tag.id)}
                                parentBackground={'dark'}
                                className={''}
                                onClick={this.handleTagClick}
                              />
                            })
                            : this.props.trip.base != null && this.props.trip.base?.id != null ?
                              <Tag
                                id={this.props.trip.base.id}
                                color={this.props.trip.base.color}
                                title={this.props.trip.base.title}
                                parentBackground={"dark"}
                              />
                              : <></>
                        }
                      </div>
                    </div>
                    <div>
                      <div className="param">
                        <LastChangeText>Última Actualización:</LastChangeText>
                        <LastChangeText>{lastChangeDateUtc === '' ? '-' : `${lastChangeDateUtc} GMT 0`}</LastChangeText>
                        <LastChangeText>{lastChangeDate === '' ? '-' : `${lastChangeDate} GMT ${symbolToShow} (hora local del usuario)`}</LastChangeText>
                      </div>
                    </div>
                  </>
                  : <>
                    <div>
                      <div className="param">
                        Etiquetas de orden:
                      </div>
                      <div className="tags-container">
                        {
                          this.state.editMode
                            ? (this.props.orderTags || []).map(tag => {
                              // debugger
                              return <Tag
                                id={tag.id}
                                color={tag.color}
                                title={tag.title}
                                type={tag.type}
                                isFull={this.state.selectedorderTags.includes(tag.id)}
                                parentBackground={'dark'}
                                className={''}
                                onClick={this.handleTagClick}
                              />
                            })
                            : (currentOrder.tags || []).map(tag => {
                              let tInfo = (this.props.orderTags || []).find(t => t.id === tag.tag);
                              if (tInfo)
                                return <Tag
                                  id={tInfo.id}
                                  color={tInfo.color}
                                  title={tInfo.title}
                                  parentBackground={"dark"}
                                />
                              return null
                            })
                        }
                      </div>
                    </div>
                    <div >
                      <div className="param">
                        Etiquetas de viaje:
                      </div>
                      <div className="tags-container">
                        {
                          this.state.editMode
                            ? (this.props.tripTags || []).map(tag => {
                              return <Tag
                                id={tag.id}
                                color={tag.color}
                                title={tag.title}
                                type={tag.type}
                                isFull={this.state.selectedTripTags.includes(tag.id)}
                                parentBackground={'dark'}
                                className={''}
                                onClick={this.handleTagClick}
                              />
                            })
                            : (trip.tags || []).map(tag => {
                              let tInfo = (this.props.tripTags || []).find(t => t.id === tag.tag);
                              if (tInfo)
                                return <Tag
                                  id={tInfo.id}
                                  color={tInfo.color}
                                  title={tInfo.title}
                                  parentBackground={"dark"}
                                />
                              return null
                            })
                        }
                      </div>
                      <div className="param">
                        <LastChangeText>Última Actualización:</LastChangeText>
                        <LastChangeText>{lastChangeDateUtc === '' ? '-' : `${lastChangeDateUtc} GMT 0`}</LastChangeText>
                        <LastChangeText>{lastChangeDate === '' ? '-' : `${lastChangeDate} GMT ${symbolToShow} (hora local del usuario)`}</LastChangeText>
                      </div>
                    </div>

                  </>
              }
            </div>
          </div>
          {
            this.state.editMode
              ? <div className="actions">
                <Button
                  type={"secondary"}
                  text={"Cancelar"}
                  onClick={this.cancelChanges}
                />
                <Button
                  type={"primary"}
                  text={this.state.isLoading ? <Loader /> : "Guardar"}
                  onClick={this.saveChanges}
                  disabled={this.state.isLoading}
                />
              </div>
              : null
          }
        </div>
        <div className="tabs">
          <div className="tabs-row">
            <TabSelectorRibbon
              activeId={this.state.selectedTab}
              items={TABS}
              onChange={this.switchTab}
              selectedUnderlineColor={"#FF9145"}
            />
          </div>
          <div className="tab-content">

            { this.getHistoryContent() }

            <div className={"map-tab tab-body ".concat(this.state.selectedTab !== "2" ? "hidden" : "")}>
              <div className="header">
                Mapa
              </div>
              <div>
                <TabSelectorRibbon
                  activeId={this.state.selectedLocationContainer}
                  items={this.state.locationContainers}
                  onChange={this.switchLocTab}
                  selectedUnderlineColor={"#45b2ff"}
                  addDownloadIcon={true}
                  openDocument={this.openCoordsDocument}
                  getUrl={this.getCoordsUrl}
                  textColor="#000"
                  bgColor="#fff"
                />
              </div>
              <div className="map-area">
                {this.mapController.getMapContainer({ className: "map-view" })}
                <button id="play-button" type="button" className="play-button" onClick={this.togglePlay}>
                  {this.state.isPlaying ? "Pausa" : "Play"}
                </button>
                <button type="button" className="play-button" style={this.state.speed === 50000 ? { fontWeight: "bold", border: "2px solid" } : {}} onClick={() => { this.setState({ speed: 50000 }) }}>
                  {"1x"}
                </button>
                <button type="button" className="play-button" style={this.state.speed === 80000 ? { fontWeight: "bold", border: "2px solid" } : {}} onClick={() => { this.setState({ speed: 80000 }) }}>
                  {"3x"}
                </button>
                <button type="button" className="play-button" style={this.state.speed === 110000 ? { fontWeight: "bold", border: "2px solid" } : {}} onClick={() => { this.setState({ speed: 110000 }) }}>
                  {"5x"}
                </button>
                <button type="button" className="play-button" style={this.state.speed === 160000 ? { fontWeight: "bold", border: "2px solid" } : {}} onClick={() => { this.setState({ speed: 160000 }) }}>
                  {"10x"}
                </button>
                {/* <div id="play-button" className="play-button" onClick={this.togglePlay}>
                  
                </div> */}
              </div>
            </div>

            { this.getCommentsContent() }

          </div>
        </div>
        {
          (this.state.shownewAppointmentModal || this.state.showEditAppointmentModal) &&
          <ChangeDateModal 
            editDate={this.state.showEditAppointmentModal}
            open={this.state.shownewAppointmentModal || this.state.showEditAppointmentModal}
            onClose={() => {
              this.setState({
                showEditAppointmentModal: false,
                shownewAppointmentModal: false,
                manueverToAssign: ''
              });
            }}
            facilityId= { this.state.manueverToAssign === 'Carga' ? (this.state.currentOrder.from_facility?.id ?? 0) : (this.state.currentOrder.to_facility?.id ?? 0) }
            orderId={this.state.currentOrder.id}
            tripDetails = {
              [this.state.currentOrder].map((detail) => {
                return {
                  ...detail, tags: detail.tags.map((o) => this.state.companyTags.find((t) => t.id === o.tag) )
                };
              })
            }
            data={{
              code: `${this.state.currentOrder.code}`,
              logo: '',
              maneuver: this.state.manueverToAssign
            }}
            reasons={this.props.orderMotives.filter((m) => m.type === 2)}
            date={
              this.state.manueverToAssign !== 'Carga' ?
              new Date(currentOrder.appointment_loading_date) :
              new Date(currentOrder.appointment_unload_date)
            }
            getInfo={ this.state.showEditAppointmentModal ? null : (info) => this.setState({appointmentChangeData: info})}
            loadTrips={() => {this.setAcceptedResponse()}}
            resetInfo={() => this.setState({appointmentChangeData: null})}
          />
        }
        <BaseDialog
          open={this.state.showUnhookModal}
          title={"Desenganche temporal remolque ".concat((this.state.historyContainers.find(c => c.id === this.state.selectedHistoryContainer) || {}).description)}
          message={<div style={{fontSize: "18px"}}>Se desengancharán todas las ordenes que tenga asignadas este remolque</div>}
          acceptText="Confirmar"
          cancelText="Cancelar"
          showCancel={true}
          cancelButtonType="outline dark primary"
          acceptAction={this.unhookContainer}
          closeAction={() => { this.setState({ showUnhookModal: false}) }}
          // contentObject={}
          style={{ content: { width: "600px", marginLeft: "-300px", overflow: "initial" } }}
          //   disabledAccept: PropTypes.bool,
          className="appointment-modal"
        />
        <BaseDialog
          open={this.state.showHookModal}
          title={"Reenganche remolque ".concat((this.state.historyContainers.find(c => c.id === this.state.selectedHistoryContainer) || {}).description)}
          message={<div style={{fontSize: "18px"}}>Se reengancharán los remolques de todas las ordenes que tenga asignadas este remolque</div>}
          acceptText="Confirmar"
          cancelText="Cancelar"
          showCancel={true}
          cancelButtonType="outline dark primary"
          acceptAction={this.hookContainer}
          closeAction={() => { this.setState({ showHookModal: false}) }}
          // contentObject={}
          style={{ content: { width: "600px", marginLeft: "-300px", overflow: "initial" } }}
          //   disabledAccept: PropTypes.bool,
          className="appointment-modal"
        />
        <ErrorDialog
          open={this.state.errorMessageModal !== null}
          message={this.state.errorMessageModal}
          acceptText="OK"
          acceptAction={() => {
            this.props.setOrderDetail(this.state.currentOrder.code);
            this.loadTrip();
            this.setState({errorMessageModal: null});
          }}
          closeAction={() => {
            this.props.setOrderDetail(this.state.currentOrder.code);
            this.loadTrip();
            this.setState({errorMessageModal: null});
          }}
        />
        <BaseDialog
          open={this.state.showDeleteEventModal}
          title="Eliminar evento"
          message=""
          acceptText="Confirmar"
          cancelText="Cancelar"
          showCancel={true}
          cancelButtonType="outline dark primary"
          acceptAction={this.deleteEvent}
          closeAction={() => { this.setState({ showDeleteEventModal: false, selectedEvent: null, deleteForAllContainers: true }) }}
          contentObject={
            <>
              <div style={{ fontSize: "16px", margin: " 20px 0 10px" }}>
                {"Estás eliminando el evento: ".concat(this.state.selectedEvent?.description)}
              </div>
              {
                this.state.historyContainers.length > 1 ? //this.numberOfEquipments() > 1 ?
                <Checkbox
                  onChange={() => { this.setState({ deleteForAllContainers: !this.state.deleteForAllContainers }) }}
                  item={{
                    id: 1,
                    description: "Eliminar para todos los contenedores",
                    active: this.state.deleteForAllContainers
                  }}
                  name={"deleteForAllContainers"}
                /> : <></>
              }
            </>
          }
          style={{ content: { width: "450px", marginLeft: "-200px", overflow: "initial" } }}
          //   disabledAccept: PropTypes.bool,
          className="appointment-modal"
        />
        <BaseDialog
          open={this.state.showEditOrderModal}
          title={this.state.titleEditOrderMdal}
          message=""
          disabledAccept={
            this.state.optionEditOrderModal === "toExistingTripConfirmation" || this.state.optionEditOrderModal === "toNewTripConfirmation" 
            ? false 
            : (
                ( 
                  this.state.optionEditOrderModal === "toExistingTrip" 
                  && (this.state.candidatesTripToChange.length === 1 
                          && this.state.candidatesTripToChange[0].title === this.state.editOrderTrip
                          && this.state.candidatesTripToChange[0].isActive)
                ) ? false 
                : (
                    (this.state.optionEditOrderModal === "numberTrip" || this.state.optionEditOrderModal === "toNewTrip") 
                    && (this.state.editOrderTrip.length > 5 && !this.state.errorEditOrderTrip)
                  ) ? false 
                    : true
              )
          }
          acceptText="Confirmar"
          cancelText="Cancelar"
          showCancel={true}
          cancelButtonType="outline dark primary"
          acceptAction={this.setModelEditOrder}
          closeAction={() => { 
            this.setState({ 
              showEditOrderModal: false, 
              editOrderTrip: "", 
              errorEditOrderTrip: false, 
              msgEditOrderTrip: "" ,
              candidatesTripToChange: [],
              selectedToChangeTripId: -1,
            }) 
          }}
          contentObject=
          {
            <div style={{ fontSize: "16px", margin: " 20px 0 10px" }}>{this.getModelEditOrder()}</div>
          }
          style={{ content: { width: "450px", marginLeft: "-200px", overflow: "initial" } }}
          //   disabledAccept: PropTypes.bool,
          className="appointment-modal"
        />
        <BaseDialog
          open={this.state.showErrorEditOrderModal}
          title="Lo sentimos"
          acceptText="Cerrar"
          showCancel={false}
          cancelButtonType="outline dark primary"
          closeAction={() => { this.setState({ showErrorEditOrderModal: false, titleErrorEditOrderMdal: "" }) }}
          acceptAction={() => { this.setState({ showErrorEditOrderModal: false, titleErrorEditOrderMdal: "" }) }}
          contentObject=
          {
            <div style={{ fontSize: "16px", margin: " 20px 0 10px" }}>{this.state.titleErrorEditOrderMdal}</div>
          }
          style={{ content: { width: "450px", marginLeft: "-200px", overflow: "initial" } }}
          className="appointment-modal"
        />
      </View>
    );
  }

  render() {
    
    return this.state.isTripAllowed ? this.mainContent() : 
    <NotAllowedContent>
      {'Este viaje no pertenece a esta compañía'}
    </NotAllowedContent>
    
  }
}

const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyType = state.globalCatalog.session.company.type.id;
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const timeZones = getEntityItems(state, "COMPANIES.SAT.CATALOGS.ZONES");
  const orderTags = companyTags.filter(tag => tag.type === 7);
  const tripTags = companyTags.filter(tag => tag.type === 9);
  const transportTags = companyTags.filter(tag => tag.type === 8);
  const companyTransportClasses = getEntityItems(state, "COMPANIES.TRANSPORTCLASS");
  const transportClassesByTag = transportTags.map(tag => {
    return ({
      id: tag.id,
      description: tag.title,
      items: (companyTransportClasses || []).filter(tC => tC.tags.map(t => t.id).includes(tag.id)).map(tC => ({ id: tC.id, description: tC.code }))
    })
  }).filter(tC => tC.items.length);
  let order = getEntityItems(state, "COMPANIES.FACILITIES.ORDERSCONTROL") || {};
  let trip = getEntityItems(state, "COMPANIES.TRIPS") || {};
  let orderHistory = getEntityItems(state, "COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.HISTORY") || {};
  let orderLocation = getEntityItems(state, "COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.LOCATIONS") || {};
  const gps_facilities = getEntityItems(state, "COMPANIES.GPS.FACILITIES.SINGLE");
  const route_geofences = getEntityItems(state, "COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.ROUTE");
  const facility_avatars = getEntityItems(state, "COMPANIES.CATALOGS.FACILITYAVATAR");
  const orderComments = (getEntityItems(state, "COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS") || []).sort((a, b) => Date.parse(b.created) - Date.parse(a.created));
  const favComments = getEntityItems(state, "COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS.FAVORITES");

  let isLoadingHistory = state?.api["COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.HISTORY"]?.status?.isFetching || false;
  let isLoadingComments = state?.api["COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS"]?.status?.isFetching || false;
  let isCommentCreating = state?.api["comment_creation"]?.status?.isFetching || false;

  const isLoadingSaveNotifications = state?.api['COMPANIES.NOTIFICATIONS.CONTROL.MESSAGES']?.status?.isFetching || false;
  const isLoadingUsersToNotify = state?.api['COMPANIES.NOTIFICATIONS.CONTROL.USERS']?.status?.isFetching || false;
  const isLoadingSettingAppointment = state?.api['COMPANIES.ORDERS']?.status?.isFetching || false;
  const isLoadingValidationTrip = state?.api['COMPANIES.TRIPS.VALIDATIONS']?.status?.isFetching || false;
  const isLoadingNotificationAction = state?.api['COMPANIES.NOTIFICATIONSV2.CANCELREQUEST']?.status?.isFetching || false;

  const orderMotives = getEntityItems(state, "COMPANIES.CATALOGS.ORDERSMOTIVES")
    .map((o) =>
      o.submotives.map((s) => ({
        label: `${o.description} - ${s.description}`,
        value: s.id,
        type: s.type,
      }))
    )
    .flat();

  return {
    isCargoOwner: `${companyType}` !== '1',
    orderMotives,
    orderSelected: state.globalCatalog.orderSelected,
    commentsVisible: state.globalCatalog.orderDetailCommentsSection,
    companyId,
    companyType,
    // companyTags,
    timeZones,
    orderTags,
    tripTags,
    companyTransportClasses,
    order,
    trip,
    orderHistory,
    orderLocation,
    gps_facilities,
    route_geofences,
    facility_avatars,
    userData: state.globalCatalog.session.user,
    companyUser: state.globalCatalog.session.company.companyuser,
    orderComments,
    favComments,
    transportClassesByTag,
    isLoadingHistory,
    isLoadingComments: isLoadingComments || isCommentCreating,
    userId: state.globalCatalog.session.user.id,
    user: state.globalCatalog.session.user,
    isNotificationLoader: isLoadingUsersToNotify || isLoadingSaveNotifications || isLoadingSettingAppointment || isLoadingNotificationAction || isCommentCreating
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(readEntities("COMPANIES.CATALOGS.FACILITYAVATAR"));
  dispatch(readEntities("COMPANIES.CATALOGS.ORDERSMOTIVES"));
  return {
    removeOrderSeleted: () => dispatch(removeOrderSeleted()),
    setOrderDetail: (payload) => dispatch(setOrderSeleted(payload)),
    loadTags: () =>
      dispatch(loadTags()),
    loadTimeZonesCatalog: (params, opt) =>
      dispatch(readEntities("COMPANIES.SAT.CATALOGS.ZONES", params, opt)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadCompanyTransportClass: (company_id) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id })),
    loadOrderInfo: (company_id, facility_id, order_id, opt) =>
      dispatch(readEntity("COMPANIES.FACILITIES.ORDERSCONTROL", { company_id, facility_id, order_id }, opt)),
    loadOrderHistory: (company_id, order_id, opt) =>
      dispatch(readEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.HISTORY", { company_id, order_id }, opt)),
    loadOrderLocations: (company_id, order_id, opt) =>
      dispatch(readEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.LOCATIONS", { company_id, order_id }, opt)),
    loadOrderRouteGeofences: (params, opt) =>
      dispatch(readEntities("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.ROUTE", params, opt)),
    loadOrderFacilityGeofences: (company_id, facilities_id, opt) =>
      dispatch(readEntities("COMPANIES.GPS.FACILITIES.SINGLE", { company_id, facilities_id }, opt)),
    loadTripInfo: (company_id, trip_id, opt) =>
      dispatch(readEntity("COMPANIES.TRIPS", { company_id, trip_id, view: "control" }, opt)),
    validateTrip: (company_id, trip_id, opt) =>
      dispatch(readEntity("COMPANIES.TRIPS.VALIDATIONS", { company_id, trip_id, action: "belonging" }, opt)),
    updateOrder: (company_id, order_id, code, opt) =>
      dispatch(partialEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER", { company_id, order_id, code }, opt)),
    updateTripControlled: (params, opt) =>
      dispatch(partialEntity("COMPANIES.FACILITIES.ORDERSCONTROL.TRIP.CONTROLLED", params, opt)),
    updateTrip: (company_id, trip_id, signature, transport_class, opt) =>
      dispatch(partialEntity("COMPANIES.FACILITIES.ORDERSCONTROL.TRIP", { company_id, trip_id, signature, transport_class }, opt)),
    updateTripBase: (company_id, trip_id, action, base, opt) =>
      dispatch(partialEntity("COMPANIES.TRIPS", { company_id, trip_id, action, base }, opt)),

    searchSignature: (company_id, signature, opt) =>
      dispatch(readEntity("COMPANIES.TRIPS.SIGNATURE", { company_id, signature }, opt)),
    findOrderTrip: (company_id, id, trip, opt) =>
      dispatch(readEntity("COMPANIES.ORDERS.TRIP", { company_id, id, trip }, opt)),
    transferOrderTrip: (company_id, id, trip, opt) =>
      dispatch(partialEntity("COMPANIES.ORDERS.TRANSFER", { company_id, id, trip }, opt)),
    newOrderTrip: (company_id, id, signature, opt) =>
      dispatch(partialEntity("COMPANIES.ORDERS.NEW", { company_id, id, signature }, opt)),

    addOrderTag: (company_id, order_id, tag_id, opt) =>
      dispatch(createEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.TAGS", { company_id, order_id, order: order_id, tag: tag_id }, opt)),
    removeOrderTag: (company_id, order_id, order_tag_id, opt) =>
      dispatch(deleteEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.TAGS", { company_id, order_id, order_tag_id }, opt)),
    addTripTag: (company_id, trip_id, tag_id, opt) =>
      dispatch(createEntity("COMPANIES.FACILITIES.ORDERSCONTROL.TRIP.TAGS", { company_id, trip_id, trip: trip_id, tag: tag_id }, opt)),
    removeTripTag: (company_id, trip_id, trip_tag_id, opt) =>
      dispatch(deleteEntity("COMPANIES.FACILITIES.ORDERSCONTROL.TRIP.TAGS", { company_id, trip_id, trip_tag_id }, opt)),
    loadOrderComments: (params, opt) =>
      dispatch(readEntities("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS", params, opt)),
    createOrderComment: (params, opt) =>
      dispatch(createEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS", params, opt)),
    setActionAppointmentRequest: (params, opt) =>
      dispatch(createEntity("COMPANIES.NOTIFICATIONSV2.CANCELREQUEST", params, opt)),
    loadFavoriteComments: (params, opt) =>
      dispatch(readEntities("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS.FAVORITES", params, opt)),
    createFavoriteComment: (params, opt) =>
      dispatch(createEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS.FAVORITES", params, opt)),
    deleteFavoriteComment: (params, opt) =>
      dispatch(deleteEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.COMMENTS.FAVORITES", params, opt)),
    deleteOrderEvent: (params, opt) =>
      dispatch(partialEntity("COMPANIES.FACILITIES.ORDERSCONTROL.ORDER.HISTORY.ELIMINATE", params, opt)),
    hookContainer: (params, opt) =>
      dispatch(partialEntity("COMPANIES.ORDERS.DETAIL.CONTAINER.HOOK", params, opt)),
    unhookContainer: (params, opt) =>
      dispatch(partialEntity("COMPANIES.ORDERS.DETAIL.CONTAINER.UNHOOK", params, opt)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    setOrderDetail: (payload) => dispatch(setOrderSeleted(payload)),
    removeToast: () => dispatch(removeToast()),
    getUsersToNotify: (params, opt) => dispatch(readEntities("COMPANIES.NOTIFICATIONS.CONTROL.USERS", params, opt)),
    saveNotifications: (params, opt) => dispatch(createEntity("COMPANIES.NOTIFICATIONS.CONTROL.MESSAGES", params, opt)),
    removeOrderCommentsSeleted: () => dispatch(removeOrderCommentsSeleted()),
    setAppointmentDate: (params, opts) => dispatch(partialEntity("COMPANIES.ORDERS", params, opts)),
  };
};

export default connect < Props, OwnProps, _, _, _, _ > (
  mapStateToProps,
  mapDispatchToProps
)(ShipmentOrderDetailView);

// const months_abbr = [
//   "Ene",
//   "Feb",
//   "Mar",
//   "Abr",
//   "May",
//   "Jun",
//   "Jul",
//   "Ago",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dic"
// ];

const LastChangeText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #afc8ff;
  opacity: 0.8;
`;

const ChipImageContent = styled.div`
  display: flex;
  align-items: center;
`;

const LinkToModal = styled.div`
  cursor: pointer;
  color: cornflowerblue;
  text-decoration: underline;
  margin-left: 8px;
`;

const DownloadButtonContainer = styled.div`
  height: 25px;
  width: 25px;
  background-color: #ebedf1;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownloadImageContent = styled.img`
  width: 12px;
  height: 12px;
`;

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;